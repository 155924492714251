import React, { useEffect, useState } from 'react';
// import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PieController } from 'chart.js';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { getTokenOwners } from '../utils/moralisService';

// ChartJS.register(ArcElement, CategoryScale, LinearScale, PieController);
ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
    contractAddress: string;
    loggerID: string;
    hasStoredToken: boolean;
}

interface TokenHolder {
    balance_formatted: string;
    owner_address: any;
}


const TokenDistributionChart = ({ contractAddress, loggerID, hasStoredToken }: Props) => {
    const [sumOfBalances, setSumOfBalances] = useState(0);

    // Define a mapping from addresses to names
    const addressNameMapping = {
        "0x858476b074563d01457e60a0019a96160e7d6faa": "Tom",
        // Add more mappings as needed
    };

    // Define an array of addresses to exclude
    const excludedAddresses = [
        "0x40ec5b33f54e0e8a33a975908c5ba1c14e5bbbdf",  // matic bridge on ethereun network
        "0x1d2bc99fa0700a969ac051fb1c57f4e330fc312a",  // reserve account on matci.
        "0x127f021A1Eeeaf502EA3B2Ef57534672A994E082"
    ];

    const [chartData, setChartData] = useState({
        index: "label index",
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# Tokens to that address',
                data: [0, 0, 0, 0, 0, 0],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    });

    //todo; once the token holders are loade sumup thebalances.


    useEffect(() => {
        if (loggerID === "0xb8e5043c3a1c745e8772badb57ca8d9bad3a8d0c3b5f74774e195c5bc2a2c204") {
            // Set specific data for the special loggerID
            setChartData({
                index: "label index",
                labels: ["Tom"],
                datasets: [{
                    ...chartData.datasets[0],
                    data: [10000]
                }]
            });
        } else {
            // Load data normally
            const loadData = async () => {
                const ownersData = await getTokenOwners(contractAddress);
                if (ownersData && ownersData.length > 0) {
                    // Filter out the matic bridge which is: 0x40ec5b33f54e0e8a33a975908c5ba1c14e5bbbdf and reserve which is: "0x1d2bc99fa0700a969ac051fb1c57f4e330fc312a"
                    const filteredData = ownersData.filter((owner: { owner_address: string; }) => !excludedAddresses.includes(owner.owner_address));
                    // const labels = filteredData.map((owner: TokenHolder) => owner.owner_address);
                    const labels = filteredData.map((owner: TokenHolder) => {
                        const nameSuffix = (addressNameMapping as { [key: string]: string })[owner.owner_address] ? ` - ${(addressNameMapping as { [key: string]: string })[owner.owner_address]}` : "";
                        return owner.owner_address + nameSuffix;
                    });
                    const balances = filteredData.map((owner: TokenHolder) => parseFloat(owner.balance_formatted));

                    // todo: in the balances filter out the account owner_address:  "0x40ec5b33f54e0e8a33a975908c5ba1c14e5bbbdf" and it's associated lable at the parallel position.
                    setChartData(prevData => ({
                        ...prevData,
                        labels,
                        datasets: [{
                            ...prevData.datasets[0],
                            data: balances
                        }]
                    }));
                    // Calculate the sum of the balances
                    const totalBalance = balances.reduce((acc: any, balance: any) => acc + balance, 0);
                    setSumOfBalances(totalBalance);

                }
            };
            loadData();
        }
    }, [contractAddress, loggerID]); // Adding loggerID to the dependency array

    return (
        <div>
            <h2>Booking Tokens in Circulation</h2>
            {chartData.datasets[0].data.length > 0 ? (
                <>
                    <Pie data={chartData} />
                    {hasStoredToken && <p>Booking Tokens in Circulation: {sumOfBalances.toFixed(2)}</p>}
                </>
            ) : (
                <p>Loading data or no data available...</p>
            )}
        </div>
    );
};

export default TokenDistributionChart;
