// loggerService.ts
import { Logger } from '../types';  // Import your Logger type

export const getAllLogs = async (apiUrl: string): Promise<Logger[]> => {
    try {
        const response = await fetch(`${apiUrl}/api/logs`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch logs');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching logs:', error);
        throw error;
    }
};

// getLogByLoggerID and return the full object that complies to this
export const getLogByLoggerID = async (apiUrl: string, loggerID: string): Promise<Logger> => {
    try {
        const response = await fetch(`${apiUrl}/api/logs/${loggerID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch log by loggerID');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching log by loggerID:', error);
        throw error;
    }
};
