import { useState, ChangeEvent, useContext, useEffect } from 'react';
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  Select,
} from '@chakra-ui/react';
import { Logo } from './Logo';
import { useMagic } from '../contexts/MagicProvider';
import { LoginProps } from '../utils/types'
import { saveUserInfo } from '../utils/common';
import showToast from '../utils/showToast';
// import { AuthContext } from '../contexts/AuthContext';

import { useNavigate } from 'react-router-dom';



import { useAuth } from '../contexts/AuthContext';

import { User } from '../types';  // Your User type
import { getApiUrl } from '../utils/helpers';


import { RPCError, RPCErrorCode } from 'magic-sdk';
import { createUser, fetchUserByEthAddress } from '../utils/userService';

export const Connect = ({ token, setToken }: LoginProps) => {
  const apiUrl = getApiUrl();
  const navigate = useNavigate();

  // Use useAuth hook to get auth context values
  const { auth, setAuthInfo } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [countryCode, setCountryCode] = useState('+61'); // Default to Australia
  const [isError, setIsError] = useState<boolean>(false);

  const { magic } = useMagic();
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [isLoginInProgress, setLoginInProgress] = useState(false);






  const validatePhoneNumber = (input: any) => {

    const phoneNumberPattern = /^\d{10}$/; // Exactly 10 digits
    // return phoneNumberPattern.test(input);
    return true;
  };

  const handleCountryCodeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCountryCode(e.target.value);
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const metadata = await magic?.user.getMetadata();
        console.log('User Metadata:', metadata);
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    fetchData();
  }, []);



  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    console.log('Input:', input);
    setIsError(!validatePhoneNumber(input)); // Validate phone number on each change

    if (validatePhoneNumber(input)) {
      // Assuming the input is valid and contains exactly 10 digits,
      // remove the first digit and prepend the country code
     
      setPhoneNumber(input);
    } else {
      // If not valid, just keep the input as it is in the state for further editing
      setPhoneNumber(input);
    }
  };

  const handleSubmit = async () => {
    if (!validatePhoneNumber(phoneNumber)) {
      setIsError(true);
    } else {
      setIsError(false);
      // Process the valid phone number
      console.log('Valid phone number:', phoneNumber);
      if (!validatePhoneNumber(phoneNumber)) {
        console.log("1. no matches")
        setPhoneError(true);
      } else {
        try {

          setLoginInProgress(true);
          setPhoneError(false);
          console.log("about to log in just modifying the phone number")

          const modifiedNumber = countryCode + phoneNumber.substring(1);

          console.log("2. modifiedNumber: ", modifiedNumber)
          const token = await magic?.auth.loginWithSMS({
            phoneNumber: modifiedNumber,
          });
          console.log("1. tom's token: ", token)
          const metadata = await magic?.user.getMetadata();
          console.log("2. Metadata: ", metadata)

          if (!token || !metadata?.publicAddress) {
            throw new Error('Magic login failed');
          }

          setToken(token);
          saveUserInfo(token, 'SMS', metadata?.publicAddress, phoneNumber);
          setAuthInfo({ token, phoneNumber, loginMethod: 'SMS', userAddress: metadata?.publicAddress });

          // Check if user exists, if not, create them
          const existingUser = await fetchUserByEthAddress(metadata.publicAddress, apiUrl);
          if (!existingUser) {
            const newUser: User = {
              addressETH: metadata.publicAddress,
              phoneNumber: phoneNumber
            };

            console.log('3. Creating new user oject based off metadata above:', newUser);
            const createdUser = await createUser(newUser, apiUrl);
            console.log('User created:', createdUser);


          } else {
            console.log('User already exists:', existingUser);
          }

          // navigate('/newlog');

        } catch (e) {
          console.log('login error: ' + JSON.stringify(e));
          if (e instanceof RPCError) {
            switch (e.code) {
              case RPCErrorCode.MagicLinkFailedVerification:
              case RPCErrorCode.MagicLinkExpired:
              case RPCErrorCode.MagicLinkRateLimited:
              case RPCErrorCode.UserAlreadyLoggedIn:
                showToast({ message: e.message, type: 'error' });
                break;
              default:
                showToast({
                  message: 'Something went wrong. Please try again',
                  type: 'error',
                });
            }
          }
        } finally {
          setLoginInProgress(false);
        }
      }
    }
  };

  return (
    <Container maxW="md">
      <Stack spacing="6">
        <Heading>Connect Your Account</Heading>
        <FormControl isInvalid={isError}>
          <FormLabel htmlFor="phone">Mobile Number</FormLabel>
          <Select id="country-code" value={countryCode} onChange={handleCountryCodeChange}>
            <option value="+61">+61 Australia</option>
            <option value="+1">+1 USA</option>
            <option value="+44">+44 UK</option>
          </Select>
          <Input
            id="phone"
            type="tel"
            value={phoneNumber}
            onChange={handleInputChange}
            placeholder="Enter your mobile number"
          />
          {isError && (
            <FormErrorMessage>
              Mobile number must be exactly 10 digits.
            </FormErrorMessage>
          )}
        </FormControl>
        <Button colorScheme="yellow" onClick={handleSubmit}>
          Connect
        </Button>
      </Stack>
    </Container>
  );
};
