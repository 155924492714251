import React, { useCallback, useState, useContext } from 'react';
import { logout } from '../../../utils/common';
import { LoginProps } from '../../../utils/types';
import { useMagic } from '../../../contexts/MagicProvider';
import Spinner from '../../Spinner';

import { useAuth } from '../../../contexts/AuthContext';
import { Button } from '@chakra-ui/react';

const Disconnect = ({ token, setToken }: LoginProps) => {
  const { setAuthInfo } = useAuth();
  const { magic } = useMagic();
  const [disabled, setDisabled] = useState(false);



  const disconnect = useCallback(async () => {
    if (!magic) return;
    try {
      setDisabled(true);
      await logout(setToken, magic);
      if (token) {
        // Update the AuthContext with the retrieved values
        setAuthInfo({
          token: '',
          phoneNumber: '',
          userAddress: '',
          loginMethod: '' // Assuming SMS as a default login method, update accordingly
        });
      }
      setDisabled(false);
    } catch (error) {
      setDisabled(false);
      console.error(error);
    }
  }, [magic, setToken, token]); // Include all relevant dependencies here
  

  return (
    <div className="wallet-method-container">
      <Button className="wallet-method" onClick={disconnect} disabled={disabled}>
        {disabled ? (
          <div className="loading-container w-[115px]">
            <Spinner />
          </div>
        ) : (
          'Logout'
        )}
      </Button>
      {/* <div className="wallet-method-desc"></div> */}
    </div>
  );
};

export default Disconnect;
