export const founders = [
    {
      role: 'Co-Founder and CTO',
      image:
        '/assets/team/lucas.png',
      name: 'Lucas Cullen',
      description: ' Lucas is really the brains behind this operation and saw a need for this from his own personal experience.',
    },
    {
      role: 'Co-Founder and Director',
      image:
      '/assets/team/tom.png',
      name: 'Thomas Miller',
      description: 'Thomas is an experienced entrepreneur with a passion for helping others and sees a gap in the market for a service like ours.',
    },
    {
      role: 'Co-Founder and CEO',
      image:
      '/assets/team/daniel.png',
      name: 'Daniel Dzian',
      description: 'Daniel is a co-founder of two prominent startups, possessing a profound passion for assisting others. He has identified a significant market gap that our service is uniquely positioned to fill.',
    },
  
  ]


  export const advisors = [
    
    {
      role: 'Manager, Business Relations',
      image:
        'https://images.unsplash.com/photo-1524660988542-c440de9c0fde?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTYwfHxibGFjayUyMGd1eXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
      name: 'Morgan Adebayo',
  
      description:
        'Consectetur libero id faucibus nisl tincidunt eget nullam fringilla urna porttitor.',
    },
    {
      role: 'Chief Operating Officer',
      image:
        'https://images.unsplash.com/photo-1522938974444-f12497b69347?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NzJ8fGJsYWNrJTIwbGFkeXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
      name: 'Bimbo Akintola',
      description:
        'Mi eget mauris pharetra et ultrices neque ornare aenean massa eget egestas purus.',
    },
    {
      role: 'Head of Human Resources',
      image:
        'https://images.unsplash.com/photo-1574034589502-9f8a1ed46fa7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTMxfHxsYWR5JTIwc21pbGluZ3xlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
      name: 'Yasmine Jones',
      description: 'Diam maecenas sed enim ut sem viverra aliquet eget magna ac placerat vestibulum.',
    },
  ]