import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Image,
  AspectRatio,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";

import { StatsWithNumbers } from "../components/StatsWithNumbers";
import { StoriesWithAccordion } from "../components/StoriesWithAccordion";
import { Opportunities } from "../components/Opportunities";
import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";

export const LearnMore = () => {
  return (
    <>
      <NavBarLogic></NavBarLogic>

      <Box as="section">
        <Container py={{ base: "16", md: "24" }}>
          <Stack spacing={{ base: "8", md: "10" }}>
            <Stack spacing={{ base: "8", md: "8" }} align="center">
              <Heading size={{ base: "sm", md: "md" }}>
                Learn More
              </Heading>
              <Text
                color="fg.muted"
                maxW="2xl"
                textAlign="center"
                fontSize="xl"
              >
                Coming Soon
              </Text>
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Box id="footer">
        <Footer />
      </Box>
    </>
  );
};
