//src/utils/common.ts
import { Magic } from './types';
import { Dispatch, SetStateAction } from 'react';
import { useAuth } from '../contexts/AuthContext';

export type LoginMethod = 'EMAIL' | 'SMS' | 'SOCIAL' | 'FORM';

export const logout = async (setToken: Dispatch<SetStateAction<string>>, magic: Magic | null) => {
  


  if (await magic?.user.isLoggedIn()) {
    await magic?.user.logout();
  }
  localStorage.setItem('token', '');
  localStorage.setItem('user', '');
  localStorage.setItem('phoneNumber', '');
  localStorage.setItem('loginMethod', '');

  setToken('');
};

export const saveUserInfo = (token: string, loginMethod: LoginMethod, userAddress: string, phoneNumber: string) => {
  localStorage.setItem('token', token);
  localStorage.setItem('isAuthLoading', 'false');
  localStorage.setItem('loginMethod', loginMethod);
  localStorage.setItem('user', userAddress);
  localStorage.setItem('phoneNumber', phoneNumber);
};
