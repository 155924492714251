import React, { useEffect, useState } from 'react';
import {
    Box,
    Image,
    Stack,
    Text,
    Spinner,
    useToast
} from '@chakra-ui/react';

import { getPhotoDetailsByIpfsHash } from '../utils/photoService';
import { getApiUrl } from '../utils/helpers';

const PhotoDisplayComponent = ({ ipfsHash }) => {
    const apiUrl = getApiUrl();

    const [photoDescription, setPhotoDescription] = useState('');
    const toast = useToast();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const photoDetails = await getPhotoDetailsByIpfsHash(apiUrl, ipfsHash); // Ensure your base URL is correct
                setPhotoDescription(photoDetails.description);
            } catch (error) {
                return error
                // toast({
                //     title: "Error",
                //     description: "Failed to load photo details.",
                //     status: "error",
                //     duration: 9000,
                //     isClosable: true,
                // });
            }
        };

        fetchData();
    }, [ipfsHash, toast]);

    return (
        <Box key={ipfsHash} mt={'8'} maxW="900px">
            <Image
                src={`https://salmon-xenogeneic-dog-987.mypinata.cloud/ipfs/${ipfsHash}?pinataGatewayToken=HAMsT2G6tnoStVHWOMakvZ9dqs5_5zrpeRFgJqZsjlCr5R_zOpTf5OWNmqQ2Hbm7`}
                alt={photoDescription || "Photo"}
                borderRadius='lg'
            />
            <Stack mt='2' spacing='3'>
                <Text><b>Photo description: </b>{photoDescription || 'No description available'}</Text>
            </Stack>
        </Box>
    );
};

export default PhotoDisplayComponent;
