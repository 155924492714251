// userService.ts

import { User } from '../types';  // Your User type

export const fetchUserByEthAddress = async (ethAddress: string, apiUrl: string): Promise<User | null> => {
  try {
    const response = await fetch(`${apiUrl}/api/users/by-eth?addressETH=${ethAddress}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('HTTP error, status = ' + response.status);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching user:', error);
    return null;
  }
};

export const createUser = async (user: any, apiUrl: string): Promise<any | null> => {
  try {
    const response = await fetch(`${apiUrl}/api/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user)
    });
    if (!response.ok) {
      throw new Error('Failed to create user');
    }
    return await response.json();
  } catch (err) {
    console.error('Error creating user:', err);
    return null;
  }
};

export const updateUser = async (user: Partial<User>, apiUrl: string): Promise<User | null> => {
  try {
    const response = await fetch(`${apiUrl}/api/users`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user)
    });
    if (!response.ok) {
      throw new Error('Failed to update user');
    }
    return await response.json();
  } catch (err) {
    console.error('Error updating user:', err);
    return null;
  }
};


export const fetchUserFirstNameLastNameByEthAddress = async (ethAddress: string, apiUrl: string): Promise<{ firstName: string, lastName: string } | null> => {
  try {
    const response = await fetch(`${apiUrl}/api/userfirstnamelastnamebyaddressETH?addressETH=${ethAddress}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('HTTP error, status = ' + response.status);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching user first name and last name:', error);
    return null;
  }
};