import { Box, Text, Button, useClipboard } from '@chakra-ui/react';

interface EventIDDisplayProps {
    eventID: string;
    eventOrLoggerID: 'event' | 'loggerID';
}

export const EventIDDisplay = ({ eventID, eventOrLoggerID }: EventIDDisplayProps) => {
    const { hasCopied, onCopy } = useClipboard(eventID);

    let label = 'Event ID:';
    if (eventOrLoggerID === 'loggerID') {
        label = 'Log ID:';
    }

    const shortenedEventID = `${eventID.slice(0, 5)}...${eventID.slice(-5)}`;

    return (
        <Box>
            <Text>
                <b>{label} </b>{shortenedEventID}
                <Button size="xs" ml={2} onClick={onCopy}>
                    {hasCopied ? 'Copied' : 'Copy'}
                </Button>
            </Text>
        </Box>
    );
};

