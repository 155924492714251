import React, { useState } from 'react';
import { Box, Button, Center, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, Spacer, Spinner, Textarea, useToast } from '@chakra-ui/react';

import { getApiUrl } from '../utils/helpers';

import { Photo } from '../types';
import { uploadPhoto } from '../utils/photoService';


interface UploadFromGalleryProps {
    userAddress: string;
    onSuccessfulUpload: () => void;  // Function to trigger after successful upload
}


export const UploadFromGallery = ({ userAddress, onSuccessfulUpload }: UploadFromGalleryProps) => {
    const apiUrl = getApiUrl();
    const [file, setFile] = useState(null);
    const toast = useToast();
    const [description, setDescription] = useState('');
    const [fileUploading, setFileUploading] = useState(false);

    const handleFileChange = (event: any) => {
        setFile(event.target.files[0]);
    };




    const uploadFile = async (event: any) => {

        setFileUploading(true)
        event.preventDefault(); // Prevent the default button action
        if (!description) {
            toast({
                title: 'Error',
                description: 'Please enter a description.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        if (!file) {
            toast({
                title: 'Error',
                description: 'No file selected.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('ethAddress', userAddress);
        formData.append('description', description);
        console.log('description: ', description);


        try {

            const response = await uploadPhoto(formData, apiUrl);
            console.log('Upload successful:', response); // Logging the response for debugging
            const ipfsLink = response?.savedPhoto?.ipfsLink; // Safely accessing nested data
            if (ipfsLink) {
                toast({
                    title: 'Uploaded Successfully',
                    description: `Your photo has been uploaded. IPFS Link: ${ipfsLink}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });

                setFileUploading(false)
                // On successful upload, call the function to trigger refetch
                onSuccessfulUpload();
                console.log('onSuccessfulUpload() called');

                // window.location.reload()
            } else {
                throw new Error('IPFS link not found in the response');
            }
        } catch (error) {
            console.error('Upload failed:', error);
            if (error instanceof Error) {
                toast({
                    title: 'Upload Error',
                    description: error.message || 'Failed to upload photo',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Upload Error',
                    description: 'Failed to upload photo',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };
    return (
        <Box flexDirection={'column'} >
            <Input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                placeholder="Select Photo"
                mt={2}
            />

            <Textarea
                placeholder="Enter a one-liner description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                mt={2}
            />

            <Button
                mt={2}
                colorScheme="teal"
                onClick={uploadFile}
                isDisabled={!file || !description.trim()} // Disable button if no file or description is provided
            >
                Upload Photo
            </Button>
            <Center>

                {fileUploading && <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
                }

            </Center>



        </Box>
    );
};

