// src/pages/Login.tsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Heading, Stack, Table, Thead, Tbody, Tr, Th, Td, FormControl, FormLabel, Input, Button, Select, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Text, useToast } from "@chakra-ui/react";
import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";
import { useAuth } from '../contexts/AuthContext';
import { useMagic } from '../contexts/MagicProvider';
import showToast from '../utils/showToast';
import { fetchUserByEthAddress, createUser, updateUser, fetchUserFirstNameLastNameByEthAddress } from "../utils/userService";
import { fetchTopInviters } from "../utils/invitationServier";
import { getApiUrl } from '../utils/helpers';
import QRCode from 'qrcode.react';


import { User } from '../types';


interface Inviter {
    _id: string; // Assuming _id is a string, adjust according to your actual data model
    totalInvites: number;
}


export const Invite = () => {
    const apiUrl = getApiUrl();
    const { setAuthInfo, auth } = useAuth();
    const navigate = useNavigate();
    const { magic } = useMagic();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [countryCode, setCountryCode] = useState('+61'); // Default to Australia
    const [isError, setIsError] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [ethAddress, setEthAddress] = useState<string>('');
    const [topInviters, setTopInviters] = useState<Inviter[]>([]);
    const toast = useToast();
    const [token, setToken] = useState(localStorage.getItem('token') || ''); // Sets to '' if null

    const [agentNames, setAgentNames] = useState<Record<string, { firstName: string, lastName: string } | null>>({});
    



    useEffect(() => {

        if (token === '') {
            console.log("No token found in browser local storage");

            navigate('/smslogin')
        } else if (token) {
            console.log("Token found in browser local storage");
        }
    }, [navigate, token]);  // Depend on `token` state


    useEffect(() => {
        async function loadTopInviters() {
            try {
                const inviters = await fetchTopInviters(apiUrl);
                setTopInviters(inviters);
                const names = await Promise.all(inviters.map(inviter =>
                    fetchUserFirstNameLastNameByEthAddress(inviter._id, apiUrl)
                ));
                const namesMap = names.reduce((acc, name, index) => {
                    acc[inviters[index]._id] = name || null; // Assign null if name is undefined
                    return acc;
                }, {} as Record<string, { firstName: string; lastName: string } | null>);
                setAgentNames(namesMap);
            } catch (error) {
                toast({
                    title: "Failed to load data",
                    description: `Error: ${error}`,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                    position: "bottom-right"
                });
            }
        }

        loadTopInviters();
    }, [apiUrl, toast]);



    // const copyInviteUrlToClipboard = () => {
    //     const inviteUrl = `https://mobiletracker.smarttradenetworks.com/smslogin?inviteCode=${auth.userAddress}`;
    //     navigator.clipboard.writeText(inviteUrl).then(() => {
    //         // toast({ message: "Invite URL copied to clipboard!", type: 'success',  isClosable: true});
    //         toast({
    //             title: "Invite URL copied to clipboard!",
    //             status: "success",
    //             duration: 9000,
    //             isClosable: true,
    //             position: "bottom-right"
    //         });
    //         console.log("toas should have shown")
    //     }, (err) => {
    //         showToast({ message: "Failed to copy URL", type: 'error' });
    //     });
    // };


    const copyInviteUrlToClipboard = (url: string) => {
        navigator.clipboard.writeText(url).then(() => {
            toast({
                title: "Invite URL copied to clipboard!",
                status: "success",
                duration: 9000,
                isClosable: true,
                position: "bottom-right"
            });
            console.log("Toast should have shown");
        }, (err) => {
            showToast({ message: "Failed to copy URL", type: 'error' });
        });
    };

    useEffect(() => {
        console.log("Auth User Address:", auth.userAddress);  // Check the current value
    }, [auth.userAddress]);  // This will log whenever auth.userAddress changes


    const inviteUrl = `https://mobiletracker.smarttradenetworks.com/smslogin?inviteCode=${auth.userAddress}`;


    // todo heper function to convert all topinvtiers addresss to firstnamem and alst name? 

    return (
        <>
            <NavBarLogic />
            <Container maxW="container.md">
                <Box as="section">
                    <Container py={{ base: "16", md: "24" }}>
                        <Stack spacing={{ base: "8", md: "10" }}>
                            <Stack spacing={{ base: "4", md: "5" }} align="center">
                                <Heading size={{ base: "sm", md: "md" }}>Invite</Heading>

                                <Text>Below is a QR code which you can get others to scan and join for your invite to show.</Text>



                                {auth.userAddress && <QRCode value={inviteUrl} size={300} />}

                                <div>
                                    <Text>Your invite url is: </Text>
                                    <Box>
                                        <Text onClick={() => copyInviteUrlToClipboard(`https://mobiletracker.smarttradenetworks.com/smslogin?inviteCode=${auth.userAddress}`)} cursor="pointer" _hover={{ textDecoration: 'underline' }} wordBreak="break-all">
                                            https://mobiletracker.smarttradenetworks.com/smslogin?inviteCode={auth.userAddress}
                                        </Text>
                                        <Button onClick={() => copyInviteUrlToClipboard(`https://mobiletracker.smarttradenetworks.com/smslogin?inviteCode=${auth.userAddress}`)} size='xs'>Click to Copy URL</Button>
                                    </Box>



                                </div>



                                <div>
                                    <Text>or invite link to our van build. </Text>
                                    <Box>
                                        <Text onClick={() => copyInviteUrlToClipboard(`https://mobiletracker.smarttradenetworks.com/log/0x4587e21467f5960efc50f6eb59684d0d9c54b919895577b9a26ba93826f3304f?inviteCode=${auth.userAddress}`)} cursor="pointer" _hover={{ textDecoration: 'underline' }} wordBreak="break-all">
                                            https://mobiletracker.smarttradenetworks.com/log/0x4587e21467f5960efc50f6eb59684d0d9c54b919895577b9a26ba93826f3304f?inviteCode={auth.userAddress}
                                        </Text>
                                        <Button onClick={() => copyInviteUrlToClipboard(`https://mobiletracker.smarttradenetworks.com/log/0x4587e21467f5960efc50f6eb59684d0d9c54b919895577b9a26ba93826f3304f?inviteCode=${auth.userAddress}`)} size='xs'>Click to Copy URL</Button>
                                    </Box>



                                </div>


                                {topInviters.length > 0 && (
                                    <Table variant="simple" size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>Inviter</Th>
                                                <Th>Number of Invites</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {topInviters.map((inviter, index) => (
                                                <Tr key={index}>
                                                    {/* todo: use fetchUserFirstNameLastNameByEthAddress  */}
                                                    {/* todo: fetchTopInviters print to the console and owrk out how to put in a chakra table.(alias) const fetchUserFirstNameLastNameByEthAddress: (ethAddress: string, apiUrl: string) => Promise<{
    firstName: string;
    lastName: string;
} | null>*/}
                                                    {/* <Td wordBreak="break-all">{inviter._id}</Td> */}
                                                    <Td>{agentNames[inviter._id] ? `${agentNames[inviter._id]!.firstName} ${agentNames[inviter._id]!.lastName}` : inviter._id}</Td>

                                                    <Td>{inviter.totalInvites}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                )}



                            </Stack>
                        </Stack>
                    </Container>
                </Box>

            </Container>

            <Footer />


        </>
    );
};
