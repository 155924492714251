import React, { useEffect, useState } from 'react';
import { Box, Container, Heading, Text, Stack, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { NavBarLogic } from '../components/NavBarLogic';
import { Footer } from '../components/Footer';
import { useAuth } from '../contexts/AuthContext';
import Camera from '../components/Camera';

import { User, Photo } from '../types'
import { PendingPhotos } from '../components/PendingPhotos';


import { uploadPhoto, fetchPendingPhotos, deletePhoto, updatePhotoDescription } from '../utils/photoService';  // Import the photo service

import { getApiUrl } from '../utils/helpers';
import { AllLogs } from '../components/AllLogs';


/**
 * The main Dashboard component that renders the user's dashboard.
 * 
 * This component handles the following functionality:
 * - Checks for stored user data (token, phone number, Ethereum address) in localStorage and sets the user state accordingly.
 * - Fetches the user's data from the API based on the stored Ethereum address.
 * - Provides a button to log the user out and navigate to the login page.
 * - Renders a camera component that allows the user to capture an image and upload it to the server.
 * - Displays some static text content for the dashboard.
 */
export const Dashboard = () => {
  const apiUrl = getApiUrl();
  const { auth, logout, setAuthInfo } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [userObjectFromMongo, setUserObjectFromMongo] = useState<User | null>(null);
  const [pendingPhotos, setPendingPhotos] = useState<Photo[]>([]);
  const [handlingCapture, setHandlingCapture] = useState(false);

// for locking the page
  const [token, setToken] = useState(localStorage.getItem('token') || ''); // Sets to '' if null
// also for locking the page and sending to the correct page
  useEffect(() => {
   
    if (token === '') {
      console.log("No token found in browser local storage");
      // setTimeout(() => navigate('/smslogin'), 10); // Delay navigation by 10 milliseconds
      // navigate('/smslogin')
    } else if (token) {
      console.log("Token found in browser local storage");
    }
  }, [navigate, token]);  // Depend on `token` state

 /**
 * Listens for changes to the `userObjectFromMongo` state and logs the updated value to the console.
 * This effect is used to monitor and respond to changes in the user object retrieved from the MongoDB database.
 */
  useEffect(() => {
    if (userObjectFromMongo) {
      console.log("Updated userObjectFromMongo:", userObjectFromMongo);
    }
  }, [userObjectFromMongo]); // Dependency array includes userObjectFromMongo to listen for changes


  /**
 * This effect checks for stored user data in the browser's local storage and fetches the user's information if it exists.
 * 
 * - If there is a stored Ethereum address, it calls `fetchUserByEthAddress` to retrieve the user's data.
 * - If there are stored token, phone number, and Ethereum address, it creates a new `User` object with the stored data and sets it in the component's state.
 * - It also sets the `authInfo` state with the stored token, phone number, Ethereum address, and login method.
 */
  useEffect(() => {
    const storedToken = localStorage.getItem('token') ?? '';
    const storedPhone = localStorage.getItem('phoneNumber') ?? '';
    const storedAddress = localStorage.getItem('user') ?? ''; // Ensure you store and retrieve the same 
    if (storedToken && storedPhone && storedAddress) {
      console.log("1. Ethereum addrsss from browser: " + storedAddress)


      const fetchData = async () => {
        try {
          const userAlreadySavedWithMongoDB = await fetchUserByEthAddress(storedAddress);

          // If userAlreadySavedWithMongoDB is not null, then we have a user in the database
          // with the same address as the one stored in the browser
          if (userAlreadySavedWithMongoDB) {
            console.log("2. User already saved with MongoDB:", userAlreadySavedWithMongoDB);
            setUserObjectFromMongo(userAlreadySavedWithMongoDB);
          } else {
            console.log("3. User not already saved with MongoDB");
            // Additional code to handle when the user is not found
          }
        } catch (error) {
          console.error("Failed to fetch user:", error);
        }
      }

      fetchData();
    }
  }, []);



  /**
 * Fetches and sets pending photos when the `userObjectFromMongo` or `handlingCapture` dependencies change.
 */
  useEffect(() => {
    fetchAndSetPendingPhotos();
  }, [userObjectFromMongo, handlingCapture]);  // Re-fetch when userObjectFromMongo or handlingCapture changes








  /**
 * Fetches a user by their Ethereum address.
 *
 * @param ethAddress - The Ethereum address of the user to fetch.
 * @returns The user data, or `null` if an error occurs during the fetch.
 */
  const fetchUserByEthAddress = async (ethAddress: User["addressETH"]) => {
    console.log("Fetching user by ETH address...");
    try {
      const response = await fetch(`${apiUrl}/api/users/by-eth?addressETH=${ethAddress}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'  // Include if you're still using ngrok
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const user = await response.json();
      console.log('Fetched user by ETH address:', user);
      setUserObjectFromMongo(user); // Save the fetched user object to state
      return user; // Return the user data
    } catch (error) {
      console.error('Error fetching user by ETH address:', error);
      return null; // Return null or handle the error as needed
    }
  };


  const handleLogout = async () => {
    await logout();
    navigate('/smslogin');
  };


  const handleCapture = async (blob: any) => {
    if (!userObjectFromMongo) {
      console.log("Capture operation skipped: No user defined.");
      return;
    }
    setHandlingCapture(true);
    const formData = new FormData();
    const file = new File([blob], "filename.jpg", { type: blob.type });
    formData.append('file', file);
    formData.append('ethAddress', userObjectFromMongo.addressETH);

    try {
      const result = await uploadPhoto(formData, apiUrl);
      console.log('File uploaded:', result);
      await fetchAndSetPendingPhotos();
    } catch (err) {
      console.error('Error during capture:', err);
    } finally {
      setHandlingCapture(false);
    }
  };

  const fetchAndSetPendingPhotos = async () => {
    if (!userObjectFromMongo) return;
    try {
      const photos = await fetchPendingPhotos(userObjectFromMongo.addressETH, apiUrl);
      setPendingPhotos(photos);
    } catch (error) {
      console.error('Failed to fetch or set pending photos:', error);
    }
  };



  const handleDeletePhoto = async (photoToDelete: Photo["ipfsLink"]) => {
    try {
      await deletePhoto(photoToDelete, apiUrl, auth.token!);
      setPendingPhotos(prevPhotos => prevPhotos.filter(photo => photo.ipfsLink !== photoToDelete));
      console.log('Photo deleted successfully');
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };



  return (
    <>
      <NavBarLogic />
      <Container >
        <Stack spacing={{ base: "8", md: "10" }}>
          {/* <Heading size={{ base: "lg", md: "xl" }}>
            Dashboard
          </Heading>
           */}
          <AllLogs />

          {/* Conditional rendering of the Camera component */}
          {/* {userObjectFromMongo && <Camera onCapture={handleCapture} />} */}



          <br />

          {/* {pendingPhotos && <PendingPhotos pendingPhotos={pendingPhotos} onDelete={handleDeletePhoto} onUpdate={updatePhotoDescription} />} */}

          <Text>
            {auth.token ? `Welcome back, user ${auth.phoneNumber}!` : 'Not logged in.'}
          </Text>
         {token && <Button colorScheme="blue" onClick={handleLogout}>
            Logout
          </Button>}
         
        </Stack>
      </Container>
      <Footer />
    </>
  )
};