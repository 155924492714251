import React, { useEffect, useState } from 'react';
import { Box, Container, Heading, Text, Stack, Button, FormControl, FormLabel, Input, Textarea, Flex, Center, Square, Spacer } from '@chakra-ui/react';
import { getApiUrl } from '../utils/helpers';

export const CollaboratorForm = () => {
  const apiUrl = getApiUrl();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    location: '',
    twitterHandle: '',
    youtubeChannel: '',
    involvement: '',
    strengthsAndPassions: ''
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
    console.log(formData)
  };

  const handleSubmitCollaboratorForm = async (e: any) => {


    console.log("button submitted")
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/api/multiPolarPeaceCollaboratorForm/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        alert('You have been sucessfully added to the collaborators list and we look forward to collaborating!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          location: '',
          twitterHandle: '',
          youtubeChannel: '',
          involvement: '',
          strengthsAndPassions: ''
        });

      } else {
        alert('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form');
    }
  };



  return (
    <>
      <Container m={5}>



        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input type='text' name='name' value={formData.name} onChange={handleChange} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input type='email' name='email' value={formData.email} onChange={handleChange} />
        </FormControl>
        <FormControl>
          <FormLabel>Phone</FormLabel>
          <Input type='tel' name='phone' value={formData.phone} onChange={handleChange} />
        </FormControl>
        <FormControl>
          <FormLabel>Location</FormLabel>
          <Input type='text' name='location' value={formData.location} onChange={handleChange} />
        </FormControl>
        <FormControl>
          <FormLabel>Twitter Handle</FormLabel>
          <Input type='text' name='twitterHandle' value={formData.twitterHandle} onChange={handleChange} />
        </FormControl>
        <FormControl>
          <FormLabel>YouTube Channel</FormLabel>
          <Input type='text' name='youtubeChannel' value={formData.youtubeChannel} onChange={handleChange} />
        </FormControl>
        <FormControl >
          <FormLabel>Involvement</FormLabel>
          <Input type='text' name='involvement' value={formData.involvement} onChange={handleChange} />
        </FormControl>
        <FormControl>
          <FormLabel>Strengths and Passions</FormLabel>
          <Textarea name='strengthsAndPassions' value={formData.strengthsAndPassions} onChange={handleChange} />
        </FormControl>

        <Flex>
          
          <Spacer />
          <Box p='4' >
            <Button mt={4} onClick={handleSubmitCollaboratorForm} bg="custom.700" boxShadow='lg' size={{ base: 'lg', md: 'xl' }} >Collaborate Now</Button>
          </Box>
        </Flex>


      </Container>

    </>
  )
};