import {
  Box,
  Card,
  CardBody,
  Image,
  Stack,
  Heading,
  Text,
  Divider,
  CardFooter,
  ButtonGroup,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  SimpleGrid,
  RadioGroup
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Photo } from '../types';
import { useAuth } from '../contexts/AuthContext';
import { getApiUrl } from '../utils/helpers';
import { deletePhoto } from '../utils/photoService';
import { useNavigate } from 'react-router-dom';



interface LogData {
  name: string;
  description: string;
  photoLink: string;
  addressETH: string; // Include any other properties that might be part of logData
}

interface NewLogComponentFormProps {
  pendingPhotos: Photo[];
  onSubmit: (logData: LogData) => Promise<void>;
  onUpdate: (ipfsLink: Photo['ipfsLink'], newDescription: Photo['description']) => Promise<void>;
}

export const NewLogComponentForm = ({ pendingPhotos, onSubmit, onUpdate }: NewLogComponentFormProps) => {
  const [selectedPhoto, setSelectedPhoto] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { auth } = useAuth(); // Use the useAuth hook to access auth object
  const apiUrl = getApiUrl();
  const navigate = useNavigate();



  useEffect(() => {
    if (!selectedPhoto && pendingPhotos.length > 0) {
      setSelectedPhoto(pendingPhotos[0].ipfsLink); // Automatically select the first photo if none is selected
    }
  }, [pendingPhotos, selectedPhoto]);


  useEffect(() => {
    console.log('Pending photos updated:', pendingPhotos);
  }, [pendingPhotos]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!selectedPhoto) {
      alert("Please select a photo.");
      return;
    }
    onSubmit({ name, description, photoLink: selectedPhoto, addressETH: auth.userAddress });
    navigate('/dashboard');
  };



  const handleDeletePhoto = async (photoToDelete: Photo["ipfsLink"]) => {
    try {
      await deletePhoto(photoToDelete, apiUrl, auth.token!);

      console.log('Photo deleted successfully');
      window.location.reload();
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };
  return (
    <Box as="form" >
      <Stack spacing={3}>
        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="" />
        </FormControl>
        <FormControl>
          <FormLabel>Description</FormLabel>
          <Textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="" />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Select a Photo</FormLabel>
          <RadioGroup value={selectedPhoto} onChange={setSelectedPhoto}>
            <SimpleGrid columns={[2, null, 3]} spacing="10px">
              {pendingPhotos.map((photo: Photo) => (
                <Box key={photo.ipfsLink} as="label">
                  <input
                    type="radio"
                    value={photo.ipfsLink}
                    style={{ display: 'none' }}
                    checked={selectedPhoto === photo.ipfsLink}
                    onChange={() => setSelectedPhoto(photo.ipfsLink)}
                  />
                  <Box
                    p={2}
                    borderWidth={1}
                    borderRadius="md"
                    borderColor={selectedPhoto === photo.ipfsLink ? 'blue.500' : 'gray.200'}
                    cursor="pointer"
                  >
                    <Image src={`https://salmon-xenogeneic-dog-987.mypinata.cloud/ipfs/${photo.ipfsLink}?pinataGatewayToken=HAMsT2G6tnoStVHWOMakvZ9dqs5_5zrpeRFgJqZsjlCr5R_zOpTf5OWNmqQ2Hbm7`} alt={photo.description} />
                    <Text mt={2}>{photo.description || 'No Description'}</Text>
                  </Box>
                  <Button variant='solid' colorScheme='red' size='xs' onClick={() => handleDeletePhoto(photo.ipfsLink)}>
                    Delete
                  </Button>
                </Box>
              ))}
            </SimpleGrid>
          </RadioGroup>
        </FormControl>
        <Button mt={4} colorScheme="yellow" type="submit" onClick={handleSubmit}>Create Log</Button>
      </Stack>
    </Box>
  );
};



