// photoService.ts
import { Photo } from '../types';  // Import your Photo type 

export const uploadPhoto = async (formData: FormData, apiUrl: string): Promise<any> => {
    try {
        const response = await fetch(`${apiUrl}/upload`, {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error uploading to server:', error);
        throw error;
    }
};

export const fetchPendingPhotos = async (ethAddress: string, apiUrl: string): Promise<Photo[]> => {
    try {
        const response = await fetch(`${apiUrl}/api/photos/pending?addressETH=${ethAddress}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'ngrok-skip-browser-warning': 'true'  // Include if you're using ngrok
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch pending photos');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching pending photos:', error);
        throw error;
    }
};

export const deletePhoto = async (photoToDelete: string, apiUrl: string, token: string): Promise<void> => {
    try {
        const response = await fetch(`${apiUrl}/api/photos/delete?ipfsLink=${photoToDelete}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error('Failed to delete photo');
        }
    } catch (error) {
        console.error('Error deleting photo:', error);
        throw error;
    }
};


export const updatePhotoDescription = async (ipfsLink: Photo["ipfsLink"], newDescription: Photo["description"], apiUrl: string) => {
    console.log("updatePhotoDescription called with", ipfsLink, newDescription, apiUrl);
    try {
        const response = await fetch(`${apiUrl}/api/photos/update`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ipfsLink, newDescription })
        });
        if (!response.ok) {
            throw new Error('Failed to update photo description');
        }
        return await response.json();
    } catch (error) {
        console.error('Error updating photo description:', error);
        throw error;
    }
};


// Get photo details by IPFS hash
export const getPhotoDetailsByIpfsHash = async (apiUrl: string, ipfsHash: Photo["ipfsLink"]): Promise<Photo> => {
    try {
        const response = await fetch(`${apiUrl}/api/photo/getPhotoDetailsByIpfsHash?ipfsHash=${ipfsHash}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Failed to get photo details');
        }
        return await response.json();
    } catch (error) {
        console.error('Error getting photo details:', error);
        throw error;
    }
};