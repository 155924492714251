import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    HStack,
    Spinner,
    Stat,
    StatLabel,
    StatNumber,
} from '@chakra-ui/react';
import { getTrackingByDateRange } from '../utils/tracking';
import { NavBarLogic } from '../components/NavBarLogic';
import { Footer } from '../components/Footer';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface DailyStats {
    date: string;
    pageViews: number;
    formSubmissions: number;
}

const TrackingStatsPage: React.FC = () => {
    const [dailyStats, setDailyStats] = useState<DailyStats[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const endDate = new Date();
                const startDate = new Date(endDate.getTime() - 30 * 24 * 60 * 60 * 1000);
                const data = await getTrackingByDateRange(startDate, endDate);

                const statsMap = new Map<string, DailyStats>();

                // Initialize the map with all dates
                for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                    const dateString = d.toISOString().split('T')[0];
                    statsMap.set(dateString, { date: dateString, pageViews: 0, formSubmissions: 0 });
                }

                // Populate the map with actual data
                data.forEach((item: any) => {
                    const date = new Date(item.timestamp).toISOString().split('T')[0];
                    const stats = statsMap.get(date) || { date, pageViews: 0, formSubmissions: 0 };
                    if (item.eventType === 'PAGE_VIEW') {
                        stats.pageViews++;
                    } else if (item.eventType === 'FORM_SUBMISSION') {
                        stats.formSubmissions++;
                    }
                    statsMap.set(date, stats);
                });

                setDailyStats(Array.from(statsMap.values()));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching tracking stats:', error);
                setError('Failed to fetch tracking stats. Please try again later.');
                setLoading(false);
            }
        };

        fetchStats();
    }, []);

    if (loading) {
        return (
            <Container centerContent>
                <Spinner size="xl" />
            </Container>
        );
    }

    if (error) {
        return (
            <Container centerContent>
                <Text color="red.500">{error}</Text>
            </Container>
        );
    }

    const totalPageViews = dailyStats.reduce((sum, day) => sum + day.pageViews, 0);
    const totalFormSubmissions = dailyStats.reduce((sum, day) => sum + day.formSubmissions, 0);

    return (
        <>
            <NavBarLogic />
            <Container maxW="container.xl" py={8}>
                <Heading as="h1" mb={6}>Tracking Statistics</Heading>
                <VStack spacing={8} align="stretch">
                    <Box>
                        <Heading as="h2" size="lg" mb={4}>Last 30 Days Overview</Heading>
                        <HStack spacing={8}>
                            <Stat>
                                <StatLabel>Total Page Views</StatLabel>
                                <StatNumber>{totalPageViews}</StatNumber>
                            </Stat>
                            {/* <Stat>
                                <StatLabel>Total Form Submissions</StatLabel>
                                <StatNumber>{totalFormSubmissions}</StatNumber>
                            </Stat> */}
                        </HStack>
                    </Box>
                    <Box>
                        <Heading as="h2" size="lg" mb={4}>Daily Statistics</Heading>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={dailyStats}>
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="pageViews" fill="#8884d8" name="Page Views" />
                                <Bar dataKey="formSubmissions" fill="#82ca9d" name="Form Submissions" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </VStack>
            </Container>
            <Footer />
        </>
    );
};

export default TrackingStatsPage;