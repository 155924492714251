import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Image,
  AspectRatio,
  SimpleGrid,
  Stack,
  Link as ChakraLink,
} from "@chakra-ui/react";

import { Opportunities } from "../components/Opportunities";
import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";

export const OpportunitiesPage = () => {
  return (
    <>
      <NavBarLogic></NavBarLogic>

      <Box id="opportunities">
        <Opportunities />
      </Box>

      <Box id="footer">
        <Footer />
      </Box>
    </>
  );
};
