import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Divider,
  Square,
  Icon,
  Badge,
  Flex,
  Spacer,
} from "@chakra-ui/react";

import { BsFillMicFill, BsStar } from "react-icons/bs";
import {
  FaAccessibleIcon,
  FaExpandAlt,
  FaPaintBrush,
  FaCarSide,
} from "react-icons/fa";
import { FaHouseCircleCheck } from "react-icons/fa6";
import { IconType } from "react-icons";
import axios from "axios";
import { OpportunityUpdates } from "./OpportunityUpdates";

// Define the possible asset types
type AssetType = "House" | "Car";

// Define the asset icon map with explicit types
const assetIconMap: { [key in AssetType]: IconType } = {
  House: FaHouseCircleCheck,
  Car: FaCarSide,
};

// Define a type for your assets
interface Asset {
  type: AssetType;
  value: number; // Assuming value is a number, adjust as needed
  // Add other asset properties here
}

export const Opportunities = () => {
  const [opportunities, setOpportunities] = useState([]);

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/opportunities`
        );
        if (!response.data) throw new Error("Data could not be fetched!");
        const data = await response.data;
        setOpportunities(data);
      } catch (error) {
        console.error("Failed to fetch opportunities:", error);
      }
    };

    fetchOpportunities();
  }, []);

  return (
    <>
      <Container py={{ base: "16", md: "24" }}>
        <Stack spacing={{ base: "16", md: "24" }}>
          <Stack spacing={{ base: "8", md: "10" }} align="center">
            <Stack spacing={{ base: "4", md: "6" }} textAlign="center">
              <Stack spacing="4">
                {/* <Text
                  fontWeight="semibold"
                  color="accent"
                  fontSize={{ base: "sm", md: "md" }}
                >
                  Stories
                </Text> */}
                <Heading size={{ base: "md", md: "lg" }}>Opportunities</Heading>
              </Stack>
            </Stack>
            <Stack
              spacing="3"
              direction={{ base: "column", md: "row" }}
              width="full"
              justify="center"
            ></Stack>
          </Stack>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            rowGap={{ base: "8", md: "12" }}
            columnGap="8"
          >
            {(opportunities || []).map((opportunity: any) => (
              <Link
                key={opportunity._id}
                _hover={{ textDecor: "none" }}
                role="group"
              >
                <Box
                  p="6"
                  bg="bg.surface"
                  boxShadow="md"
                  _groupHover={{ boxShadow: "xl" }}
                  transition="all 0.2s"
                  height="full"
                >
                  <Stack
                    spacing={{ base: "4", lg: "4" }}
                    justify="space-between"
                    height="full"
                  >
                    <Stack spacing="1">
                      <Box overflow="hidden">
                        <Flex>
                          <Spacer />
                          <Badge colorScheme="purple">
                            {opportunity.status}
                          </Badge>
                        </Flex>

                        <Image
                          src={opportunity.image}
                          alt={opportunity.title}
                          width="full"
                          height="15rem"
                          objectFit="cover"
                        />
                      </Box>
                      <Text mb={5} fontSize="xs">
                        Image AI generated to protect family privacy.
                      </Text>
                      <Stack spacing="1">
                        {/* Concatenate tags to form a category-like string */}
                        <Text
                          fontSize="sm"
                          fontWeight="semibold"
                          color="accent"
                        >
                          {opportunity.tags.join(", ")}
                        </Text>

                        <Heading size="xs">{opportunity.title}</Heading>
                        <Text color="fg.muted">{opportunity.description}</Text>
                        <Text >ID: {opportunity._id}</Text>
                      </Stack>
                    </Stack>
                    <Heading as="h6" size="xs" mb={-3}>
                      Details
                    </Heading>
                    <Divider />
                    <Stack spacing="1">
                      <Stack spacing="1">
                        <Text fontWeight="medium">Name: {opportunity.description.split(" ")[0]}</Text>
                        <Text fontWeight="medium">
                          Offer period: March 30, 2024
                        </Text>
                      </Stack>
                      <HStack>
                        {/* <Avatar src="https://tinyurl.com/2p98t7nh" boxSize="10" /> */}
                      </HStack>
                      {(opportunity.assets || []).map((asset: any) => (
                        <HStack
                          key={asset.type}
                          spacing={{ base: "1", md: "1" }}
                        >
                          <Square
                            size={{ base: "10", md: "12" }}
                            bg="accent"
                            color="fg.inverted"
                            borderRadius="lg"
                          >
                            <Icon
                              as={
                                assetIconMap[
                                asset.type as keyof typeof assetIconMap
                                ] || FaExpandAlt
                              }
                              boxSize={{ base: "5", md: "6" }}
                            />
                          </Square>
                          <Stack spacing={{ base: "1", md: "2" }} flex="1">
                            <Text
                              fontSize={{ base: "lg", md: "xl" }}
                              fontWeight="medium"
                            >
                              {asset.type}: ${asset.value.toLocaleString()}
                            </Text>
                            {/* Additional asset details can be displayed here */}
                          </Stack>
                        </HStack>
                      ))}
                    </Stack>
                    {/* Additional details about the opportunity, like ask and offer */}
                    <Divider />
                    <Stack spacing="1">
                      <Text fontWeight="medium">
                        Ask: ${opportunity.ask.toLocaleString()}
                      </Text>
                      <Text fontWeight="medium">
                        Targeted IRR: {((opportunity.offer - 1) * 100).toFixed(0)}%
                      </Text>
                      <Text color="fg.muted">
                        Length of fund: {opportunity.duration} months
                      </Text>
                    </Stack>
                    <Divider />

                   

                    <Button
                      onClick={() => {
                        // Check the opportunity's status
                        if (opportunity.status === "Funded") {
                          // If status is "Funded", navigate to the updates page for this opportunity
                          window.location.href = `/opportunity/${opportunity._id}`;
                        } else {
                          // For other statuses, navigate to the request offer page
                          // This assumes each opportunity has a `requestOfferUrl` property.
                          // const offerUrl = opportunity.requestOfferUrl;
                          const offerUrl = "/requestoffer"; // Fallback or example offer URL
                          window.location.href = offerUrl;
                        }
                      }}
                    >
                      {opportunity.status === "Funded" ? "View Updates" : "Request Offer"}
                    </Button>

                  </Stack>
                </Box>
              </Link>
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </>
  );
};
