import { alertTheme } from './alert'
import { avatarTheme } from './avatar'
import { badgeTheme } from './badge'
import { buttonTheme } from './button'
import { checkboxTheme } from './checkbox'
import { closeButtonTheme } from './close-button'
import { containerTheme } from './container'
import { customSelectTheme } from './custom-select'
import { dividerTheme } from './divider'
import { formTheme } from './form-control'
import { formLabelTheme } from './form-label'
import { headingTheme } from './heading'
import { inputTheme } from './input'
import { menuTheme } from './menu'
import { modalTheme } from './modal'
import { progressTheme } from './progress'
import { radioTheme } from './radio'
import { selectTheme } from './select'
import { sliderTheme } from './slider'
import { switchTheme } from './switch'
import { tableTheme } from './table'
import { tabsTheme } from './tabs'
import { tagTheme } from './tag'
import { textareaTheme } from './textarea'

export const components = {
  Alert: alertTheme,
  Avatar: avatarTheme,
  Badge: badgeTheme,
  Button: buttonTheme,
  Checkbox: checkboxTheme,
  CloseButton: closeButtonTheme,
  Container: containerTheme,
  CustomSelect: customSelectTheme,
  Divider: dividerTheme,
  FormLabel: formLabelTheme,
  Heading: headingTheme,
  Input: inputTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  Progress: progressTheme,
  Form: formTheme,
  Radio: radioTheme,
  Select: selectTheme,
  Slider: sliderTheme,
  Switch: switchTheme,
  Table: tableTheme,
  Tabs: tabsTheme,
  Tag: tagTheme,
  Textarea: textareaTheme,
}
