// src/pages/Home.tsx
import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Image,
  AspectRatio,
  SimpleGrid,
  Stack,
  Link as ChakraLink,
  Avatar,
  HStack,
  Icon,
  Wrap,
  Tag,
  Center
} from "@chakra-ui/react";




import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";
import { useName } from "../contexts/NameContext";

import { Card as Card2 } from "../components/Card"
import { HiLocationMarker, HiShieldCheck } from 'react-icons/hi'

import { influencers } from "../data/inflencers";
import { CollaboratorForm } from "./CollaboratorForm";
import { logPageView } from "../utils/tracking"; // Add this import


interface Influencer {
  name: string;
  about: string;
  channelLink: string;
  country: string;
  profileImage: string;
  tags: string[];
}

export const HomePage = () => {
  const [randomisedInfluencers, setRandomisedInfluencers] = useState<Influencer[]>([]);

  const [showCollaboratorForm, setshowCollaboratorForm] = useState(false);




  //todo: create a function that takes the influencers and randomisesd them and saves them as randomisedInfluencers using setRandomisedInflencers so it cna be used instead.
  // Function to randomize influencers
  const randomizeInfluencers = () => {
    const shuffled = [...influencers]
      .sort(() => Math.random() - 0.5);
    setRandomisedInfluencers(shuffled);
  };

  // Call randomizeInfluencers and log page view when the component mounts
  useEffect(() => {
    randomizeInfluencers();
    logPageView('Home Page');
  }, []);


  return (
    <>
      <NavBarLogic ></NavBarLogic>




      <Box as="section" id="hero" bg="custom.500">
        <>
          <Image
            alt="Placeholder Image"
            src="../assets/hero.webp"
            objectFit="cover"
            // objectPosition="center -140px"
            // maxH={{ base: 'sm', md: 'lg' }}
            width="full"
          />

          {/* <Box id="timer">
            <CountdownTimer />
          </Box> */}



          <Container mt={5} mb={30}>
            <Stack spacing={{ base: '4', md: '6' }} align="center" textAlign="center">
              <Stack spacing="3">
                {/* <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight="medium" >
                  Pricing
                </Text> */}
                <Heading size={{ base: 'md', md: 'lg' }} fontWeight="semibold">
                  Welcome to MultiPolarPeace: A New Era of Global Harmony
                </Heading>
              </Stack>
              <Heading size={{ base: 'sm', md: 'md' }}>Discover Unity in Diversity</Heading>
              <Text color="fg.accent.muted" fontSize={{ base: 'lg', md: 'xl' }} maxW="3xl">
                At Multipolar Peace, we embrace the richness of a multipolar world committed to harmony and understanding. We believe in the power of collective action and shared insights to foster global peace. Join us in this transformative journey as we explore diverse perspectives and bridge the gaps between different cultures and nations.
              </Text>
            </Stack>
          </Container>
        </>


      </Box>



      <Box bg="custom.900">
        <Container py={{ base: '16', md: '24' }}>
          <SimpleGrid columns={{ base: 1, md: 1 }} gap={16}>
            <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
              <Stack spacing={{ base: '4', md: '6' }}>
                <Heading size={{ base: 'md', md: 'xl' }}>What, Why and Who</Heading>
                <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                  Multipolar Peace is first and foremost a resource site. It brings together online channels & voices that are part of a global dialogue towards multipolarity, neutrality and peace & prosperity.

                </Text>



                <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">

                  Who: It is a team effort, kicked off by a group of concerned global citizens.  They are: Warwick Powell, Lena Petrova, Pascal Lottaz, Jeff Rich, Arnaud Bertrand, David Oualaalou, Einar Tangen, Digby Wren, Anna Rosario Malingdog-Uy, John Pang, SL Kanthan, Jerry Grey, Kathleen Tyson, Danny Haiphong, Thomas Fazi, Hussein Askary with support and encouragement from a bunch of others.

                </Text>
                <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                  Why: We came about in response to rising concerns of global instability and increased risk of warfare, particularly on the back of NATO’s global expansion ambitions.
                </Text>
                <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                  As a network Multipolar Peace is a team effort. At the same time, each platform’s voice is unique. That’s our own version of multipolarity in action.                </Text>
                <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                  We hope that by amplifying diverse voices, the message can be heard that Multipolar Peace is possible.                </Text>
                <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                  In addition to providing access to information and analysis, you can also get involved. As a community of concerned global citizens, we can come together to advance the cause of prosperity and peace.               </Text>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="3">

                {/* <ChakraLink href="">
                  <Button bg="custom.700" boxShadow='lg' size={{ base: 'lg', md: 'xl' }}>Subscribe Now</Button>
                </ChakraLink> */}

              </Stack>
            </Stack>
            {/* <AspectRatio ratio={1}>
              <Image objectFit="cover" src="/assets/youtube/dailypicks.webp" alt="Lady at work" />
            </AspectRatio> */}
          </SimpleGrid>
        </Container>


      </Box>

      <Box bg="custom.800">
        <Container py={{ base: '16', md: '24' }}>
          <Box gap={16}>
            <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
              <Stack spacing={{ base: '4', md: '6' }}>
                <Heading size={{ base: 'md', md: 'xl' }}>Keep up to Date + Get Involved</Heading>
                <Text fontSize={{ base: 'lg', md: 'xl' }} >
                  Keep up to date with daily picks, and also let us know if you want to get more involved in promoting Multipolar Peace. Click here:
                </Text>
              </Stack>
              <Stack spacing="3">
                {!showCollaboratorForm && <Button onClick={() => setshowCollaboratorForm(!showCollaboratorForm)} bg="custom.700" boxShadow='lg' size={{ base: 'lg', md: 'xl' }}>
                  Collaborate Now
                </Button>}
                <div></div>
                <div></div>
                <div></div>
                {showCollaboratorForm && <CollaboratorForm />}
              </Stack>
            </Stack>

            <AspectRatio ratio={1}>
              <Image objectFit="cover" src="/assets/youtube/dailypicks.webp" alt="Lady at work" />
            </AspectRatio>
          </Box>
          <Box mt={30} />
          <div >

          </div>
          {/* {showCollaboratorForm && <CollaboratorForm />} */}
        </Container>
      </Box>



      <Box as="section" bg="custom.900">
        <Container py={{ base: '16', md: '24' }}>
          <Stack spacing={{ base: '8', md: '10' }}>
            <Stack spacing={{ base: '4', md: '5' }} align="center">
              <Heading size={{ base: 'sm', md: 'md' }}>Our Mission</Heading>
              <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
                We are dedicated to uniting individuals who champion the cause of a peaceful, multipolar world. Here, you will find a curated list of mainstream influencers who advocate for peace and positivity across global spheres. Through dialogue and collaboration, we aim to inspire actions that resonate with the values of harmony and mutual respect.
              </Text>
            </Stack>

          </Stack>
        </Container>
      </Box>


      <Box as="section" bg="custom.500">
        <Container py={{ base: '16', md: '24' }}>
          <Stack spacing={{ base: '8', md: '10' }}>
            <Stack spacing={{ base: '4', md: '5' }} align="center">
              <Heading size={{ base: 'sm', md: 'md' }}>Multipolarity</Heading>
              <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
                Multipolarity is a reality already, but its institutions and forms remain work in progress. Multipolarity recognises and respects diversity of civilisations, and believes peace and prosperity can be built by supporting respectful dialogue and trans-national collaboration.
              </Text>
            </Stack>

          </Stack>
        </Container>
      </Box>

      <Box as="section" bg="custom.500">
        <Container py={{ base: '16', md: '24' }}>
          <Stack spacing={{ base: '8', md: '10' }}>
            <Stack spacing={{ base: '4', md: '5' }} align="center">
              <Heading size={{ base: 'sm', md: 'md' }}>Neutrality</Heading>
              <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
                Neutrality is the opposite of “building blocs” defined against a third party. Neutrality is an ethos that enables the engagement with others on the basis of mutual respect and mutual benefit. Countries and communities working together can and must be done in ways that aren’t defined against others.
              </Text>
            </Stack>

          </Stack>
        </Container>
      </Box>


      <Box as="section" bg="custom.500">
        <Container py={{ base: '16', md: '24' }}>
          <Stack spacing={{ base: '8', md: '10' }}>
            <Stack spacing={{ base: '4', md: '5' }} align="center">
              <Heading size={{ base: 'sm', md: 'md' }}>No NATO expansion</Heading>
              <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
                The expansion of NATO in Europe and potentially into other parts of the world has been one of the main causes of conflict, warfare and bloodshed. Multipolarity does not need alliances like NATO.
              </Text>
            </Stack>

          </Stack>
        </Container>
      </Box>








      <Box as="section" bg="custom.500">
        <Container py={{ base: '16', md: '24' }}>
          <Stack spacing={{ base: '8', md: '10' }}>
            <Stack spacing={{ base: '4', md: '5' }} align="center">
              <Heading size={{ base: 'sm', md: 'md' }}>Join Our Community</Heading>
              <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
                Become a part of our growing community of peace advocates. Subscribe to keep up to date, or tell us about how you’d like to be involved.  Our network of independent content creators are helping people globally understand what’s at stake and what needs to be done. Let's build a future where diverse voices lead the way to understanding and peace.
              </Text>
              <div></div>
              <div></div>
            </Stack>

          </Stack>
          <div></div>
          <div></div>
          <div></div>
          <Center>

            {!showCollaboratorForm && <Button onClick={() => setshowCollaboratorForm(!showCollaboratorForm)} bg="custom.700" boxShadow='lg' size={{ base: 'lg', md: 'xl' }}>
              Collaborate Now
            </Button>}


          </Center>

          <div></div>
          <div></div>
          <div></div>
          {showCollaboratorForm && <CollaboratorForm />}
        </Container>
      </Box>







      {randomisedInfluencers.map((influencer) => (
        <Box as="section" bg='gray.100' p="6" key={influencer.name}>
          <Card2>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '3', md: '10' }}
              align="flex-start"
            >
              <Stack spacing="4">
                <Avatar
                  size="2xl"
                  src={influencer.profileImage || "/assets/youtube/default-avatar.jpg"}
                  name={influencer.name}
                />
                <ChakraLink href={influencer.channelLink} target="_blank">
                  <Button width="full" bg="custom.700">
                    View
                  </Button>
                </ChakraLink>
              </Stack>
              <Box>
                <Stack spacing={{ base: '1', md: '2' }} direction={{ base: 'column', md: 'row' }}>
                  <Text as="h2" fontWeight="bold" fontSize="xl">
                    {influencer.name}
                  </Text>
                  <HStack fontSize={{ base: 'md', md: 'lg' }}>
                    <Icon as={HiShieldCheck} color="green.500" />
                  </HStack>
                </Stack>
                <Text mt="2">
                  {influencer.about}
                </Text>
                <Wrap shouldWrapChildren my="4" spacing="4">
                  <HStack spacing="1">
                    <Icon as={HiLocationMarker} color="gray.400" />
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      color='gray.800'
                    >
                      {influencer.country}
                    </Text>
                  </HStack>
                </Wrap>
                <Wrap shouldWrapChildren mt="5" color='gray.800'>
                  {influencer.tags.map((tag) => (
                    <Tag key={tag} color="inherit" px="3">
                      {tag}
                    </Tag>
                  ))}
                </Wrap>
              </Box>
            </Stack>
          </Card2>
        </Box>
      ))}







      <Box as="section" id="about">
        {/* <StoriesWithAccordion /> */}
        {/* <AboutUs /> */}




      </Box>





      <Box >
        {/* <StatsWithNumbers /> */}
        {/* <Center> */}

        {/* <Flex justifyContent="center" alignItems="center" w="100%" h="100%"> Centers content both horizontally and vertically */}

        {/* </Flex> */}
        {/* </Center> */}
      </Box>
      <Box bg="custom.800">
        <Container py={{ base: '16', md: '24' }}>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
            <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
              <Stack spacing={{ base: '4', md: '6' }}>
                <Heading size={{ base: 'md', md: 'xl' }}>Keep up to Date + Get Involved</Heading>
                <Text fontSize={{ base: 'lg', md: 'xl' }} >
                  Keep up to date with daily picks, and also let us know if you want to get more involved in promoting Multipolar Peace. Click here:
                </Text>
              </Stack>

              <Stack direction={{ base: 'column', md: 'row' }} spacing="3">
                <Button onClick={() => setshowCollaboratorForm(!showCollaboratorForm)} bg="custom.700" boxShadow='lg' size={{ base: 'lg', md: 'xl' }}>
                  Collaborate Now
                </Button>
              </Stack>
            </Stack>


          </SimpleGrid>
          <Box mt={30} />
          <div >

          </div>
          {showCollaboratorForm && <CollaboratorForm />}
        </Container>
      </Box>



      {/* <Box id="sponsors"> */}
      {/* <Opportunities /> */}
      {/* <Sponsors /> */}
      {/* </Box> */}

      {/* <Box id="news"> */}
      {/* <Opportunities /> */}
      {/* <News /> */}
      {/* </Box> */}

      {/* <Box id="events">
        
        <Events />
      </Box> */}

      <Box id="footer">
        <Footer />


      </Box>
    </>
  );
};
