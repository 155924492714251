// src/pages/Login.tsx
import React, { useCallback, useContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import showToast from '../utils/showToast';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Image,
  AspectRatio,
  SimpleGrid,
  Stack,
  Spacer,
  Divider,
  FormControl,
  Input,
  FormLabel
} from "@chakra-ui/react";

import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";

import { Avatar, Center, Flex, Icon, VStack } from '@chakra-ui/react'
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im'

import { Connect } from '../components/Connect';
import { useMagic } from '../contexts/MagicProvider';
import Disconnect from "../components/magic/wallet-methods/Disconnect";

// import { setAuthInfo } from 

import { useAuth } from '../contexts/AuthContext';

/**
 * The `Login` component is the main entry point for the login functionality in the application.
 * It handles the SMS-based login flow, including retrieving and storing user authentication information.
 * The component also includes a form for users to submit sponsor information, which is handled asynchronously.
 */
export const Login = () => {
  // Use useAuth hook to get auth context values
  const { auth, setAuthInfo } = useAuth();
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userAddress, setUserAddress] = useState('');


  // Use useMagic hook to get magic instance
  const { magic } = useMagic();





  useEffect(() => {
    const storedToken = localStorage.getItem('token') ?? '';
    const storedPhone = localStorage.getItem('phoneNumber') ?? '';
    const storedAddress = localStorage.getItem('user') ?? '';

    if (storedToken && process.env.REACT_APP_MAGIC_API_KEY) {
      setAuthInfo({
        token: storedToken,
        phoneNumber: storedPhone,
        userAddress: storedAddress,
        loginMethod: 'SMS'
      });
      navigate('/dashboard');

      setToken(storedToken);
      setPhoneNumber(storedPhone);
      setUserAddress(storedAddress);

      console.log('Stored Token:', storedToken);
      console.log('Stored Phone:', storedPhone);
      console.log('Stored Address:', storedAddress);
    }
  }, []);  // Removed dependencies to ensure this only runs once on mount



  const getMetadata = useCallback(async () => {
    console.log('getMetadata')
    console.log('magic', magic)
    if (!magic) return;
    try {
      setDisabled(true);
      const userInfo = await magic.user.getInfo();
      console.log('userInfo', userInfo);
      setDisabled(false);
      showToast({
        message: `Public Address: ${userInfo.publicAddress}`,
        type: 'success',
      });
    } catch (error) {
      setDisabled(false);
      console.error(error);
    }
  }, [magic]);




  // Optionally, manage loading and error states
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    companyName: '',
    mobileNumber: '',
    website: '',
    email: ''
  });
 


  return (
    <>
      <NavBarLogic></NavBarLogic>

      <Box as="section">
        <Container py={{ base: "16", md: "24" }}>
          <Stack spacing={{ base: "8", md: "10" }}>
            <Stack spacing={{ base: "4", md: "5" }} align="center">
              <Heading size={{ base: "sm", md: "md" }}>
                SMS Login
              </Heading>
              {/* <Text
                color="fg.muted"
                maxW="2xl"
                textAlign="center"
                fontSize="xl"
              >
                Fill in the following questions below to see if you qualify.
              </Text> */}
              <Button onClick={getMetadata}> GetMetaData </Button>
              <Disconnect token={token} setToken={setToken}></Disconnect>
              <Box border="1">
                <Text>Data from AuthContext</Text>
                <Divider></Divider>
                {auth?.token && (
                  <div>
                    Logged In: Yes<br />
                    {/* Token: {auth?.token}<br /> */}
                    Phone Number: {auth?.phoneNumber}<br />
                    User Address: {auth?.userAddress}
                  </div>
                )}



              </Box>
              <div>todo: if logged in show status here and update live and also show phoen number, user and token</div>
              <Divider></Divider>

              {/* Displaying Local Storage Data */}
              {token && (
                <div>
                  <strong>Local Storage Data:</strong><br />
                  Phone Number: {phoneNumber}<br />
                  User Address: {userAddress}
                </div>
              )}

            </Stack>
          </Stack>
        </Container>
      </Box>

      <Box py={{ base: '12', md: '24' }} maxW="7xl" mx="auto">
        <Stack spacing="12">
          <Flex flex="1">
            <Connect token={token} setToken={setToken} />

          </Flex>
          <Center flex="1" px={{ lg: '8' }} display={{ base: 'none', lg: 'flex' }}>
            <Spacer />
            <VStack spacing="8">
              <Stack direction="row" spacing="3">
                <Icon as={ImQuotesLeft} boxSize="8" mt="-4" />
                <Heading size="sm" fontWeight="medium" textAlign="center">
                  What can I say - I fell in love with Tracker the moment I started using it. It's the perfect solution for our business.
                </Heading>
                <Icon as={ImQuotesRight} boxSize="8" alignSelf="end" />
              </Stack>
              <VStack spacing="4">
                <Avatar
                  size="lg"
                  src="https://avatars.githubusercontent.com/u/20296626?v=4"
                  name="Simon Holzmayer"
                />
                <Stack textAlign="center" spacing="1">
                  <Text textStyle="md" fontWeight="medium" color="fg.muted">
                    Thomas Miller
                  </Text>
                  <Text fontWeight="medium" textStyle="sm" color="fg.muted">
                    dynamict@protonmail.com
                  </Text>
                </Stack>
                {/* <Rating defaultValue={5} /> */}

                
              </VStack>
            </VStack>
          </Center>
        </Stack>
      </Box>

      <Box id="footer">
        <Footer />
      </Box>
    </>
  );
};