export enum Network {
  POLYGON_AMOY = 'polygon-amoy',
  POLYGON = 'polygon',
  ETHEREUM_SEPOLIA = 'ethereum-sepolia',
  ETHEREUM = 'ethereum',
  ZKSYNC = 'zksync',
  ZKSYNC_SEPOLIA = 'zksync-sepolia',
  BSC = 'bsc', // Binance Smart Chain Mainnet
  BSC_TESTNET = 'bsc-testnet' // Binance Smart Chain Testnet
}

export const getNetworkUrl = () => {
  switch (process.env.REACT_APP_PUBLIC_BLOCKCHAIN_NETWORK) {
    case Network.POLYGON:
      return 'https://polygon-rpc.com/';
    case Network.POLYGON_AMOY:
      return 'https://rpc-amoy.polygon.technology/';
    case Network.ETHEREUM_SEPOLIA:
      return 'https://eth-sepolia.g.alchemy.com/v2/fYFybLQFR9Zr2GCRcgALmAktStFKr0i0';
    case Network.ETHEREUM:
      return 'https://eth-mainnet.g.alchemy.com/v2/fYFybLQFR9Zr2GCRcgALmAktStFKr0i0';
    case Network.ZKSYNC:
      return 'https://mainnet.era.zksync.io';
    case Network.ZKSYNC_SEPOLIA:
      return 'https://sepolia.era.zksync.dev';
    case Network.BSC:
      return 'https://bsc-dataseed.binance.org/';
    case Network.BSC_TESTNET:
      return 'https://data-seed-prebsc-1-s1.binance.org:8545/';
    default:
      throw new Error('Network not supported');
  }
};

export const getChainId = () => {
  switch (process.env.REACT_APP_PUBLIC_BLOCKCHAIN_NETWORK) {
    case Network.POLYGON:
      return 137;
    case Network.POLYGON_AMOY:
      return 80002;
    case Network.ETHEREUM_SEPOLIA:
      return 11155111;
    case Network.ZKSYNC:
      return 324;
    case Network.ZKSYNC_SEPOLIA:
      return 300;
    case Network.ETHEREUM:
      return 1;
    case Network.BSC:
      return 56; // Mainnet chain ID
    case Network.BSC_TESTNET:
      return 97; // Testnet chain ID
  }
};

export const getNetworkToken = () => {
  switch (process.env.REACT_APP_PUBLIC_BLOCKCHAIN_NETWORK) {
    case Network.POLYGON_AMOY:
    case Network.POLYGON:
      return 'MATIC';
    case Network.ETHEREUM:
      return 'ETH';
    case Network.ETHEREUM_SEPOLIA:
    case Network.ZKSYNC:
    case Network.ZKSYNC_SEPOLIA:
    case Network.BSC:
    case Network.BSC_TESTNET:
      return 'BNB';

  }
};

export const getFaucetUrl = () => {
  switch (process.env.REACT_APP_PUBLIC_BLOCKCHAIN_NETWORK) {
    case Network.POLYGON_AMOY:
      return 'https://faucet.polygon.technology/';
    case Network.ETHEREUM_SEPOLIA:
      return 'https://sepoliafaucet.com/';
    case Network.ZKSYNC_SEPOLIA:
      return 'https://faucet.quicknode.com/ethereum/sepolia'
    case Network.BSC_TESTNET:
      return 'https://testnet.binance.org/faucet-smart';
    case Network.BSC:
      return `https://bscscan.com/address/`;
    case Network.BSC_TESTNET:
      return `https://testnet.bscscan.com/address/`;
  }
};

export const getNetworkName = () => {
  switch (process.env.REACT_APP_PUBLIC_BLOCKCHAIN_NETWORK) {
    case Network.POLYGON:
      return 'Polygon (Mainnet)';
    case Network.POLYGON_AMOY:
      return 'Polygon (Amoy)';
    case Network.ETHEREUM_SEPOLIA:
      return 'Ethereum (Sepolia)';
    case Network.ETHEREUM:
      return 'Ethereum (Mainnet)';
    case Network.ZKSYNC:
      return 'zkSync (Mainnet)';
    case Network.ZKSYNC_SEPOLIA:
      return 'zkSync (Sepolia)';
  }
};

export const getBlockExplorer = (address: string) => {
  switch (process.env.REACT_APP_PUBLIC_BLOCKCHAIN_NETWORK) {
    case Network.POLYGON:
      return `https://polygonscan.com/address/${address}`;
    case Network.POLYGON_AMOY:
      return `https://www.oklink.com/amoy/address/${address}`;
    case Network.ETHEREUM:
      return `https://etherscan.io/address/${address}`;
    case Network.ETHEREUM_SEPOLIA:
      return `https://sepolia.etherscan.io/address/${address}`;
    case Network.ZKSYNC:
      return `https://explorer.zksync.io/address/${address}`;
    case Network.ZKSYNC_SEPOLIA:
      return `https://sepolia.explorer.zksync.io/address/${address}`;
  }
};
