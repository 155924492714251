import { extendTheme } from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme';
import { components } from './components';
import * as foundations from './foundations';
import { styles } from './styles';

export const theme = extendTheme({
  ...proTheme, // This spreads the entire proTheme including its colors
  styles,
  components,
  colors: {
    // ...proTheme.colors, // Correctly spread the colors from proTheme
    // ...foundations.colors, // Assuming you have additional color settings
    custom: {
      100: "#d7cbb4",
      200: "#8B0000",
      300: "#4A90E2",
      400: "#7BAF9E",
      500: "#D9CAB3",
      600: "#F4D03F",
      700: "#D98719",
      800: "#00a293",
      900: "#B39DDB",
      // You might want to avoid using 1000 and 1100 as they are non-standard in Chakra UI
    }
  }
});
