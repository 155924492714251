// Determines the API base URL based on the location of the app
export const getApiUrl = () => {
    const hostname = window.location.hostname;
  
    if (hostname === "localhost") {
      return "http://localhost:8090";  // Local development server URL
    } else {
      return "https://stnexpressbackendserver-9i9k3.ondigitalocean.app";  // Production server URL
    }
  };
  