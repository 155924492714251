//src/utils/bookingService.ts

import { Booking, BookingInput } from '../types';

const apiUrl = 'http://your-api-url.com'; // Adjust to your actual API endpoint

// Function to create a new booking
export const createBooking = async (bookingData: BookingInput): Promise<Booking> => {
    try {
        const response = await fetch(`${apiUrl}/bookings`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bookingData)
        });

        if (!response.ok) {
            throw new Error('Failed to create booking');
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating booking:', error);
        throw error;
    }
};

// Function to get all bookings by loggerID
export const getBookingsByLoggerID = async (loggerID: string): Promise<Booking[]> => {
    try {
        const response = await fetch(`${apiUrl}/bookings/logger/${loggerID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch bookings');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching bookings:', error);
        throw error;
    }
};

// Function to update a booking
export const updateBooking = async (bookingID: string, bookingData: Partial<BookingInput>): Promise<void> => {
    try {
        const response = await fetch(`${apiUrl}/bookings/${bookingID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bookingData)
        });

        if (!response.ok) {
            throw new Error('Failed to update booking');
        }

        return await response.json();  // Depending on API to return updated booking or just confirmation
    } catch (error) {
        console.error('Error updating booking:', error);
        throw error;
    }
};

// Function to delete a booking
export const deleteBooking = async (bookingID: string): Promise<void> => {
    try {
        const response = await fetch(`${apiUrl}/bookings/${bookingID}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to delete booking');
        }
    } catch (error) {
        console.error('Error deleting booking:', error);
        throw error;
    }
};
