// src/App.tsx
import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Button,
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import MagicProvider from "./contexts/MagicProvider";
import { AuthProvider } from "./contexts/AuthContext";
import { theme } from "./theme";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/Home";
import { Apply } from "./pages/Apply";
import { LearnMore } from "./pages/LearnMore";
import { RequestOffer } from "./pages/RequestOffer";
import { OpportunitiesPage } from "./pages/OpportunitiesPage";
import { Team } from "./pages/Team";
import { Log } from "./pages/log/index";
import { CalendarView } from "./pages/calendar/index";
import { Login } from "./pages/Login";
import { Dashboard } from "./pages/Dashboard";
import { NewLog } from "./pages/NewLog";
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import { SMSLogin } from "./pages/SMSLogin";
import { Invite } from './pages/Invite';
import TrackingStatsPage from './pages/TrackingStatsPage';


// const client = new ApolloClient({
//   link: new HttpLink({
//     uri: 'https://a7aa-103-108-229-78.ngrok-free.app/graphql', // Adjust this to your GraphQL server URI
//   }),
//   cache: new InMemoryCache()
// });

/**
 * The main App component that serves as the entry point for the application.
 * It sets up the necessary providers and routers to handle the different pages and functionality of the application.
 */
export const App = () => (
  // <ApolloProvider client={client}>
    <MagicProvider>
      <AuthProvider>
        <ChakraProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/apply" element={<Apply />} />
              <Route path="/learnmore" element={<LearnMore />} />
              <Route path="/requestoffer" element={<RequestOffer />} />
              <Route path="/opportunities" element={<OpportunitiesPage />} />
              <Route path="/team" element={<Team />} />
              <Route path="/log/:loggerID" element={<Log />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/login" element={<Login />} />
              <Route path="/newlog" element={<NewLog />} />
              <Route path="/smslogin" element={<SMSLogin />} />
              <Route path="/invite" element={<Invite />} />
              <Route path="/calendar/:loggerID" element={<CalendarView />} />
              <Route path="/stats" element={<TrackingStatsPage />} />
              


            </Routes>
          </Router>
        </ChakraProvider>
      </AuthProvider>
    </MagicProvider>
  // </ApolloProvider>
);
