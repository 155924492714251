import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";


const TokenInfoModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button onClick={onOpen} colorScheme="blue">Learn More About Booking Tokens</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Booking Tokens Explained</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Booking tokens are ERC-20 tokens that can be used to book this asset. If you woudl like to qualify for more benefits you are required to KYC.
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="yellow" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
export default TokenInfoModal;