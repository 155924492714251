import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Select,
  Checkbox,
  Textarea,
} from '@chakra-ui/react'
import React, { useState } from 'react';
import axios from 'axios';


export const RequestOfferForm = (props: BoxProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [caseRequested, setCaseRequested] = useState('');




  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Form data to send
    const formData = {
      firstName,
      lastName,
      email,
      phone,    
      caseRequested,
    };
    console.log(formData)

    try {
      const response = await axios.post(`'${process.env.REACT_APP_API}/apply`, formData);
      console.log('Form submitted successfully', response.data);
      // Handle success here, e.g., showing a success message or redirecting
    } catch (error) {
      console.error('Error submitting form', error);
      // Handle error here, e.g., showing an error message
    }
  };

  return (

    <>
      <Box as="form" bg="bg.surface" boxShadow="sm" borderRadius="lg" {...props}>
        <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
          {/* Existing form fields */}
          <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
            <FormControl id="firstName">
              <FormLabel>First Name</FormLabel>
              <Input defaultValue="" onChange={(e) => setFirstName(e.target.value)} />
            </FormControl>
            <FormControl id="lastName">
              <FormLabel>Last Name</FormLabel>
              <Input defaultValue="" onChange={(e) => setLastName(e.target.value)} />
            </FormControl>
          </Stack>
        
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input defaultValue="" onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl id="phone">
            <FormLabel>Phone</FormLabel>
            <Input type="string" defaultValue="" onChange={(e) => setPhone(e.target.value)} />
          </FormControl>
       
          {/* Field for describing the case */}
          <FormControl id="caseDescription">
            <FormLabel>Request Details</FormLabel>
            <Textarea
              placeholder="Please specify the offer you are interested in and detail your reasons for this interest. Your insights will assist us in providing you with the most relevant information."
              maxLength={700} // Approximately 100 words, assuming average word length of 7 characters
              onChange={(e) => setCaseRequested(e.target.value)}
            />
          </FormControl>
        </Stack>
        <Divider />
        <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
          <Button onClick={handleSubmit}>Apply</Button>
        </Flex>
      </Box>


    </>
  )


}