// src/utils/tracking.ts
import axios from 'axios';
import { getApiUrl } from './helpers';

const apiUrl = getApiUrl();

export const logPageView = async (pageName: string) => {
    try {
        await axios.post(`${apiUrl}/api/multiPolarPeacetracking`, {
            eventType: 'PAGE_VIEW',
            pageName,
            referrer: document.referrer,
        });
    } catch (error) {
        console.error('Error logging page view:', error);
    }
};

export const logFormSubmission = async (formData: any) => {
    try {
        await axios.post(`${apiUrl}/api/multiPolarPeacetracking`, {
            eventType: 'FORM_SUBMISSION',
            pageName: 'Collaborate Form',
            formData,
        });
    } catch (error) {
        console.error('Error logging form submission:', error);
    }
};

export const getTrackingByDateRange = async (startDate: Date, endDate: Date) => {
    try {
        const response = await axios.get(`${apiUrl}/api/multiPolarPeacetracking/date-range`, {
            params: {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString()
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching tracking data:', error);
        throw error;
    }
};