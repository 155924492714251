import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";


const UnaccountedForEvent = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {/* <Button onClick={onOpen} colorScheme="blue">Learn More About Booking Tokens</Button> */}
            <Button colorScheme='blue' size='xs' variant='outline' onClick={onOpen}>What does "Unaccounted For" mean?</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Unaccounted For Events Explained</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <p>
                            An "Unaccounted For" event refers to any occurrences where time and resources have not been
                            adequately included in the ERC20 token distribution. Such events require a thorough review and must be signed off by at
                            least two other members of the team to be eledigibe for condiseration at the next event review meeting. If you have an account with your phone nubmer you will be invited to the next meeting.
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="yellow" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
export default UnaccountedForEvent;