import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

type AuthInfo = {
  token: string;
  phoneNumber: string;
  loginMethod: string;
  userAddress: string;
};

const defaultAuthInfo: AuthInfo = { token: '', phoneNumber: '', loginMethod: '', userAddress: '' };

const AuthContext = createContext<{
  auth: AuthInfo;
  setAuthInfo: (authInfo: AuthInfo) => void;
  logout: () => void;
}>({
  auth: defaultAuthInfo,
  setAuthInfo: () => {},
  logout: () => {}
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState<AuthInfo>(defaultAuthInfo);

  useEffect(() => {
    // Load auth data from localStorage if available
    const storedAuth = {
      token: localStorage.getItem('token') || '',
      phoneNumber: localStorage.getItem('phoneNumber') || '',
      loginMethod: localStorage.getItem('loginMethod') || 'SMS',
      userAddress: localStorage.getItem('user') || ''
    };
    setAuth(storedAuth);
  }, []);

  const setAuthInfo = (authInfo: AuthInfo) => {
    localStorage.setItem('token', authInfo.token);
    localStorage.setItem('phoneNumber', authInfo.phoneNumber);
    localStorage.setItem('loginMethod', authInfo.loginMethod);
    localStorage.setItem('user', authInfo.userAddress);
    setAuth(authInfo);
  };

  const logout = async () => {
    
    localStorage.clear();
    setAuth(defaultAuthInfo);
  };

  return (
    <AuthContext.Provider value={{ auth, setAuthInfo, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
