import React, { useState, useEffect } from 'react';
import { Logger } from '../types';
import { getApiUrl } from '../utils/helpers';

const LoggerDetails = ({ loggerID }: { loggerID: string }) => {
    const apiUrl = getApiUrl();
    const [logger, setLogger] = useState<Logger | null>(null);
    const [newLoggerID, setNewLoggerID] = useState('0x532799fcedadb94b041d5f800f2d858b3ed29445385baaa422f43380b13ab42d');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Fetch logger details from the server
    useEffect(() => {
        const fetchLogger = async () => {
            console.log("LoggerDetails.tsx: New loggerID:", newLoggerID);
            try {
                const response = await fetch(`${apiUrl}/api/logs/${newLoggerID}`);
                // const response = await fetch(`http://localhost:8090/api/logs/${newLoggerID}`);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setLogger(data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch logger details: ' );
                setLoading(false);
            }
        };

        fetchLogger();
    }, [loggerID]);  // Re-run the effect if loggerID changes



    return (
        <div>
            <h1>Logger Details</h1>
            <div>apiUrl: {apiUrl}</div>
            <p><strong>ID:</strong> {loggerID}</p>
            <p><strong>Name:</strong> {logger?.name}</p>
            <p><strong>Description:</strong> {logger?.description}</p>
            <p><strong>Photo Link:</strong> {logger?.photoLink}</p>
            <p><strong>Address ETH:</strong> {logger?.addressETH}</p> 
        </div>
    );
};

export default LoggerDetails;
