// src/components/EventsComponent.tsx
import {
    Box,
    Card,
    CardBody,
    Image,
    Stack,
    Heading,
    Text,
    Divider,
    CardFooter,
    ButtonGroup,
    Button,
    FormControl,
    FormLabel,
    Input,
    Flex,
    Spacer,
    Badge,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Spinner,
    Center,
    Link as ChakraLink,
    CheckboxGroup,
    SimpleGrid,
    Checkbox,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FocusableElement } from '@chakra-ui/utils';

import { Photo, Logger, Event, AdditionalEventData } from '../types';
import { useAuth } from '../contexts/AuthContext';

// import { EventIDDisplay } from '../components/EventIDDisplay';

import { EventIDDisplay } from '../components/EventIDDisplay';


import { getPhotoDetailsByIpfsHash, deletePhoto, fetchPendingPhotos } from '../utils/photoService';
import { getApiUrl } from '../utils/helpers';


import { addAdditionalEventData, deleteAdditionalEventDataByEventID, getAdditionalEventDataByEventID } from '../utils/eventService'
import { fetchUserFirstNameLastNameByEthAddress } from '../utils/userService'
import PhotoDisplayComponent from './PhotoDisplayComponent';
import { UploadFromGallery } from './UploadFromGallery';
import UnaccountedForEvent from './UnaccountedForEvent';




interface EventsComponentProps {
    loggerID: Logger['loggerID'];
    events: Event[];
}

export const EventsComponent = ({ loggerID, events }: EventsComponentProps) => {
    const apiUrl = getApiUrl();

    const { auth } = useAuth(); // Use the useAuth hook to access auth object
    const [photos, setPhotos] = useState<Record<string, Photo>>({});


    const [additionalEventData, setAdditionalEventData] = useState<Record<string, AdditionalEventData[]>>({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedEventID, setSelectedEventID] = useState<string | null>(null);
    const [manHours, setManHours] = useState<number>();
    const [agentNames, setAgentNames] = useState<Record<string, { firstName: string, lastName: string } | null>>({});
    const [loadingNames, setLoadingNames] = useState<boolean>(true);  // Add loading state

    const numberOfEvents = events.length;
    const uniqueAgents = new Set(events.map(event => event.addressETH)).size;
    const [hasStoredToken, setHasStoredToekn] = useState(false);


    // state to support uploading photos and selecteing pending photos to save an addiitiaonl event data object that saves photoos links with descriptions and man hours when the press save on the modal after sleclecting the photos .in this format:  AdditionalEventData {
    //     loggerID: string; // Required loggerID associated with the additional event data.
    //     addressETH: string; // Required Ethereum address of the user adding the additional data.
    //     eventID: string; // Required identifier for the event.
    //     photoID: string[]; // Required array of photo IDs associated with the additional data.
    //     manHours?: number; // Optional man hours reported for the additional data.
    //     addedAt?: Date; // Optional: Automatically generated timestamp for when the additional data was added.
    //   }
    const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
    const [pendingPhotos, setPendingPhotos] = useState<Photo[]>([]); // State for pending photos
    const [triggerPhotoRefetch, setTriggerPhotoRefetch] = useState(false);


    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const onCloseAlert = () => setIsAlertOpen(false);
    const cancelRef = useRef<FocusableElement | null>(null);








    useEffect(() => {
        const storedToken = localStorage.getItem('token') ?? '';

        if (storedToken) {
            // console.log("1. Ethereum addrsss from browser: " + storedAddress)
            setHasStoredToekn(true);  // Set true if token exists


        }
    }, []);



    useEffect(() => {
        console.log("EventsComponent.tsx: events:", events);

        const fetchPhotos = async () => {
            const photoPromises = events.flatMap(event =>
                (event.photoLinks ?? []).map(async (ipfsHash) => {
                    const photo = await getPhotoDetailsByIpfsHash(apiUrl, ipfsHash);
                    return { ipfsHash, photo };
                })
            );

            try {
                const photosArray = await Promise.all(photoPromises);
                const photosMap = photosArray.reduce((acc, { ipfsHash, photo }) => {
                    acc[ipfsHash] = photo;
                    return acc;
                }, {} as Record<string, Photo>);
                setPhotos(photosMap);
            } catch (error) {
                console.error('Error fetching photo details:', error);
            }
        };

        fetchPhotos();

    }, [events, apiUrl]);

    useEffect(() => {
        const fetchAllAdditionalEventData = async () => {
            for (const event of events) {
                await fetchAdditionalEventData(event.eventID);
            }
        };

        if (events.length > 0) {
            fetchAllAdditionalEventData();
        }
    }, [events]);


    useEffect(() => {
        const fetchPhotos = async () => {
            console.log("Fetching pending photos");
            if (!auth.userAddress) return;
            try {
                const photos = await fetchPendingPhotos(auth.userAddress, apiUrl);
                setPendingPhotos(photos);
                console.log(photos);
            } catch (error) {
                console.error("Failed to fetch pending photos:", error);
            }
        };

        fetchPhotos();
    }, [apiUrl, auth.userAddress, triggerPhotoRefetch]);



    const fetchAdditionalEventData = async (eventID: string) => {
        try {
            const data = await getAdditionalEventDataByEventID(apiUrl, eventID);
            console.log("Setting additional event data:", data);
            setAdditionalEventData(prevState => ({
                ...prevState,
                [eventID]: data
            }));
        } catch (error) {
            console.error('Error fetching additional event data:', error);
        }
    };


    useEffect(() => {
        const fetchAgentNames = async () => {
            const uniqueAddresses = Array.from(new Set(events.map(event => event.addressETH).concat(
                events.flatMap(event => additionalEventData[event.eventID]?.map(data => data.addressETH) || [])
            )));

            const namesPromises = uniqueAddresses.map(async (addressETH) => {
                if (addressETH) {
                    const names = await fetchUserFirstNameLastNameByEthAddress(addressETH, apiUrl);
                    return { addressETH, names };
                }
                return { addressETH: '', names: null };
            });

            try {
                const namesArray = await Promise.all(namesPromises);
                const namesMap = namesArray.reduce((acc, { addressETH, names }) => {
                    if (addressETH) {
                        acc[addressETH] = names;
                    }
                    return acc;
                }, {} as Record<string, { firstName: string, lastName: string } | null>);
                setAgentNames(namesMap);
                setLoadingNames(false);  // Update loading state
            } catch (error) {
                console.error('Error fetching agent names:', error);
                setLoadingNames(false);  // Update loading state even if there is an error
            }
        };

        if (events.length > 0) {
            fetchAgentNames();
        }
    }, [events, additionalEventData, apiUrl]);




    // const handleAddManHoursAndPhotos = async () => {
    //     // todo: if no selected photos or manhours is 0 do an alert to ask them to select photos and manhours first before submitting and if they sa no I want to submi as is then submit the datat as is?
    //     if (!selectedPhotos.length) return;
    //     if (!selectedEventID) return;

    //     const additionalData: Partial<AdditionalEventData> = {
    //         addressETH: auth.userAddress,
    //         loggerID,
    //         eventID: selectedEventID,
    //         manHours,
    //         photoID: selectedPhotos
    //     };

    //     try {
    //         await addAdditionalEventData(apiUrl, selectedEventID, additionalData);
    //         onClose();
    //         fetchAdditionalEventData(selectedEventID);
    //         setTriggerPhotoRefetch(prev => !prev)
    //     } catch (error) {
    //         console.error('Error adding man hours:', error);
    //     }
    // };



    const handleAddManHoursAndPhotos = async () => {
        // Always open the alert dialog regardless of the condition
        setIsAlertOpen(true);
    };

    const submitData = async () => {
        // Your existing logic to submit data
        if (!selectedEventID) return;

        const additionalData: Partial<AdditionalEventData> = {
            addressETH: auth.userAddress,
            loggerID,
            eventID: selectedEventID,
            manHours,
            photoID: selectedPhotos
        };

        try {
            await addAdditionalEventData(apiUrl, selectedEventID, additionalData);
            onClose();
            fetchAdditionalEventData(selectedEventID);
            setTriggerPhotoRefetch(prev => !prev);
        } catch (error) {
            console.error('Error adding man hours:', error);
        }
    };



    const calculateTotalManHours = () => {
        return events.reduce((total, event) => {
            const additionalHours = (additionalEventData[event.eventID] || []).reduce(
                (sum, data) => sum + (data.manHours || 0),
                0
            );
            return total + (event.manHours || 0) + additionalHours;
        }, 0);
    };

    const getAgentName = (addressETH: string) => {
        const names = agentNames[addressETH];
        console.log("names:", names);
        return names ? `${names.firstName} ${names.lastName}` : addressETH;
    };


    const deleteAdditionalEventData = async (eventID: string) => {
        try {
            await deleteAdditionalEventDataByEventID(apiUrl, eventID);
            // Remove the deleted event data from the state
            setAdditionalEventData(prevState => {
                const newState = { ...prevState };
                delete newState[eventID]; // Assuming you want to remove all data associated with the eventID
                return newState;
            });
            console.log('Deletion successful.');
        } catch (error) {
            console.error('Error deleting additional event data:', error);
        }
    };

    const handleDeletePhoto = async (photoToDelete: Photo["ipfsLink"]) => {
        try {
            await deletePhoto(photoToDelete, apiUrl, auth.token!);

            console.log('Photo deleted successfully');
            setTriggerPhotoRefetch(prev => !prev)
            // reloadAndReadPosition()
        } catch (error) {
            console.error('Error deleting photo:', error);
        }
    };


    return (
        <Box as="section" borderRadius='lg' borderColor='gray.200'>
            <Heading ml={1} as='h4' size='sm'>Events</Heading>
            <Text>Total Hours: {calculateTotalManHours()}</Text>
            <Text>Total Number of Events: {numberOfEvents}</Text>
            <Text>Unique Agents Reported: {uniqueAgents}</Text>

            {loadingNames ? (
                <Spinner />
            ) : (
                <Stack spacing={20}>

                    {events.map((event) => (
                        <Card key={event.eventID} maxW={900} dropShadow={"md"} >
                            <CardBody>
                                <Flex direction={'column'}>
                                    <Box>
                                        <Text fontSize='xl' fontWeight='bold'>
                                            <Badge maxWidth={"auto"} ml='1' fontSize='0.8em' colorScheme='green'>{event.eventNumber}</Badge>
                                        </Text>
                                    </Box>
                                </Flex>
                                <Flex direction={"row"}>
                                    <Spacer></Spacer>
                                    <Text><b>Created At: </b>{event.createdAt ? new Date(event.createdAt).toLocaleString() : 'N/A'}</Text>
                                </Flex>
                                <Heading size="sm">{event.name}</Heading>
                                {(event.photoLinks ?? []).map(ipfsHash => {
                                    const photo = photos[ipfsHash];
                                    return (
                                        <Box key={ipfsHash}>
                                            {/* <Image
                                                src={`https://salmon-xenogeneic-dog-987.mypinata.cloud/ipfs/${ipfsHash}?pinataGatewayToken=HAMsT2G6tnoStVHWOMakvZ9dqs5_5zrpeRFgJqZsjlCr5R_zOpTf5OWNmqQ2Hbm7`}
                                                alt={photo?.description || "Photo"}
                                                borderRadius='lg'
                                            /> */}
                                            <Stack mt='6' spacing='3'>
                                                {/* <Text><b>Photo description: </b>{photo?.description }</Text> */}

                                                <PhotoDisplayComponent ipfsHash={ipfsHash} ></PhotoDisplayComponent>
                                            </Stack>
                                        </Box>
                                    );
                                })}

                                <Divider />
                                <CardFooter>
                                    <Flex direction={'column'}>
                                        <Flex direction={'column'}>
                                            {event.eventID ? (
                                                <EventIDDisplay eventID={event.eventID} eventOrLoggerID='event' />
                                            ) : (
                                                <Text><b>Event ID: </b></Text>
                                            )}
                                            <Spacer />
                                            <Text><b>Comments: </b>{event.description} </Text>
                                            {event.addressETH && <Text><b>Agent: </b> {getAgentName(event.addressETH)}</Text>}
                                            <Text><b>Hours worked: </b> {event.manHours}</Text>
                                            <Text>
                                                <b>Event Date: </b>
                                                {event.eventDate ? new Date(event.eventDate).toLocaleDateString() : 'Event happened the same day it was reported.'}
                                            </Text>
                                        </Flex>
                                        <Flex direction={'column'}>
                                            {/* <Box borderWidth={1} p={2} mt={4} borderRadius={5}> */}
                                            <Box  p={2} mt={4} >
                                                <Text><b>Additional Event Data</b></Text>
                                                {additionalEventData[event.eventID]?.map((data) => (
                                                    data.addressETH && (
                                                        <Box borderWidth={1} p={2} borderRadius={5} mb={5}>

                                                            {/* <Text key={event.eventID}><b>EventID: </b> {event.eventID}<b>Agent Reporting: </b>{getAgentName(data.addressETH)} {data.manHours} hours</Text> */}
                                                            <Text key={event.eventID}><b>Agent Reporting: </b>{getAgentName(data.addressETH)} {data.manHours} hours</Text>
                                                            {/* todo map through the string of data.photoID[]   and get the photoID and render  <PhotoDisplayComponent ipfsHash={data.photoID.map}></PhotoDisplayComponent> */}
                                                            {/* Render a PhotoDisplayComponent for each IPFS hash */}
                                                            {data.photoID.map((ipfsHash, index) => (
                                                                <PhotoDisplayComponent key={index} ipfsHash={ipfsHash} />
                                                            ))}
                                                            <Spacer />
                                                            {/* Show delete button only if the logged-in user is the one who added the event data */}
                                                            {auth.userAddress === data.addressETH && (
                                                                <Button colorScheme="red" size='xs' onClick={() => {
                                                                    deleteAdditionalEventData(event.eventID)
                                                                        .then(() => {
                                                                            console.log('Deletion successful.');
                                                                        })
                                                                        .catch(error => {
                                                                            console.error('Error deleting additional event data:', error);
                                                                        });
                                                                }}>
                                                                    Delete
                                                                </Button>
                                                            )}




                                                        </Box>

                                                    )
                                                ))}
                                                <Button size='xs' onClick={() => {
                                                    setSelectedEventID(event.eventID);
                                                    fetchAdditionalEventData(event.eventID);
                                                    onOpen();

                                                }}>
                                                    Add individual hours or photos to this event
                                                </Button>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                    <ButtonGroup spacing='2'>
                                    </ButtonGroup>


                                </CardFooter>
                                <Flex p='4'>

                                    <Spacer />

                                    <Box p='4' bg='red.400' borderRadius={5}>
                                        Event unaccounted for
                                    </Box>



                                    {/* <Box p='4' bg='green.400'>
                                        Box 2
                                    </Box> */}
                                </Flex>
                                <Flex >

                                    <Spacer />



                                    <UnaccountedForEvent />


                                    {/* <Box p='4' bg='green.400'>
    Box 2
</Box> */}
                                </Flex>
                            </CardBody>
                        </Card>
                    ))}


                </Stack>
            )}








            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add man hours and photos</ModalHeader>
                    <ModalCloseButton />

                    {!hasStoredToken ? (
                        <Center>
                            <ChakraLink href="/smslogin">
                                <Button mb={10}>Login to add hours</Button>
                            </ChakraLink>

                        </Center>
                    ) : (
                        <>
                            <ModalBody>
                                <FormControl>
                                    <FormLabel>Man Hours</FormLabel>
                                    <Input type="number" value={manHours} onChange={(e) => setManHours(Number(e.target.value))} />
                                </FormControl>
                                <br></br>
                                <FormLabel>Or choose or upoad photos.</FormLabel>
                                <UploadFromGallery
                                    userAddress={auth.userAddress}
                                    onSuccessfulUpload={() => setTriggerPhotoRefetch(prev => !prev)}  // Toggle the state to trigger refetch
                                />
                                <br></br>




                                <FormControl >

                                    <FormLabel>or if you have already uploaded then checkmark as many photos as necessary to support the event.</FormLabel>
                                    <CheckboxGroup value={selectedPhotos} onChange={(values) => setSelectedPhotos(values as string[])}>
                                        <SimpleGrid columns={[2, null, 3]} spacing="10px">
                                            {pendingPhotos.map((photo) => (
                                                <Box key={photo.ipfsLink} flexDirection={"column"}>

                                                    <Image src={`https://salmon-xenogeneic-dog-987.mypinata.cloud/ipfs/${photo.ipfsLink}?pinataGatewayToken=HAMsT2G6tnoStVHWOMakvZ9dqs5_5zrpeRFgJqZsjlCr5R_zOpTf5OWNmqQ2Hbm7`} alt={photo.description} />
                                                    <Flex p={2}>
                                                        <Checkbox value={photo.ipfsLink}>
                                                            {photo.description || 'No Description'}
                                                        </Checkbox>
                                                        <Spacer />
                                                        <Button variant='solid' colorScheme='red' size='xs' onClick={() => handleDeletePhoto(photo.ipfsLink)}>
                                                            Delete
                                                        </Button>


                                                    </Flex>

                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                    </CheckboxGroup>


                                </FormControl>

                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleAddManHoursAndPhotos}>
                                    Save
                                </Button>
                                <Button onClick={onClose}>Cancel</Button>

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>


            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Missing Information
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            You have chosen to submit {manHours} hours and {selectedPhotos.length} photos, are you sure you want to attach this data to the event?
                            {/* Are you sure you want to submit without selecting any photos or entering man hours? */}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            {/* <Button ref={cancelRef} onClick={onCloseAlert}> */}
                            <Button onClick={onCloseAlert}>

                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={() => { submitData(); onCloseAlert(); }} ml={3}>
                                Submit Anyway
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Box>
    );
};