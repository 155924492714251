import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define a type for the context value
type NameContextType = {
    name: string;
    updateName: (newName: string) => void;
  };

  // Create the context with an initial empty value
const NameContext = createContext<NameContextType | undefined>(undefined);

// Custom hook for using the context
export const useName = () => {
  const context = useContext(NameContext);
  if (context === undefined) {
    throw new Error('useName must be used within a NameProvider');
  }
  return context;
};

// Define props type for the provider component
type NameProviderProps = {
  children: ReactNode;
};

export const NameProvider: React.FC<NameProviderProps> = ({ children }) => {
  const [name, setName] = useState<string>('CMD.');

  const updateName = (newName: string) => {
    setName(newName);
  };

  return (
    <NameContext.Provider value={{ name, updateName }}>
      {children}
    </NameContext.Provider>
  );
};