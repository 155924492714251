import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Select,
  Checkbox,
  Textarea,
} from '@chakra-ui/react'
import React, { useState } from 'react';
import axios from 'axios';


export const ApplicationFormCard = (props: BoxProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [parentingMatter, setParentingMatter] = useState(false);
  const [otherSideLawyerName, setOtherSideLawyerName] = useState('');
  const [propertyValue, setPropertyValue] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postcode, setPostcode] = useState('');
  const [caseDescription, setCaseDescription] = useState('');




  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Form data to send
    const formData = {
      firstName,
      lastName,
      dob,
      gender,
      parentingMatter,
      otherSideLawyerName,
      propertyValue,
      street,
      city,
      state,
      postcode,
      caseDescription,
    };
    console.log(formData)

    try {
      const response = await axios.post(`'${process.env.REACT_APP_API}/apply`, formData);
      console.log('Form submitted successfully', response.data);
      // Handle success here, e.g., showing a success message or redirecting
    } catch (error) {
      console.error('Error submitting form', error);
      // Handle error here, e.g., showing an error message
    }
  };

  return (

    <>
      <Box as="form" bg="bg.surface" boxShadow="sm" borderRadius="lg" {...props}>
        <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
          {/* Existing form fields */}
          <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
            <FormControl id="firstName">
              <FormLabel>First Name</FormLabel>
              <Input defaultValue="" onChange={(e) => setFirstName(e.target.value)} />
            </FormControl>
            <FormControl id="lastName">
              <FormLabel>Last Name</FormLabel>
              <Input defaultValue="" onChange={(e) => setLastName(e.target.value)} />
            </FormControl>
          </Stack>
          {/* Additional fields start here */}
          <FormControl id="dob">
            <FormLabel>Date of Birth</FormLabel>
            <Input type="date" defaultValue="" onChange={(e) => setDob(e.target.value)} />
          </FormControl>
          <FormControl id="gender">
            <FormLabel>Gender</FormLabel>
            <Select placeholder="Select gender" onChange={(e) => setGender(e.target.value)} >
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </Select>
          </FormControl>
          <Checkbox id="parentingMatter" isChecked={parentingMatter} onChange={(e) => setParentingMatter(e.target.checked)}>Please check the box if this is also a parenting matter.</Checkbox>
          <FormControl id="otherSideLawyerName">
            <FormLabel>Other Side Lawyer Name</FormLabel>
            <Input defaultValue="" onChange={(e) => setOtherSideLawyerName(e.target.value)} />
          </FormControl>
          <FormControl id="propertyValue">
            <FormLabel>Property Value</FormLabel>
            <Input type="number" defaultValue="" onChange={(e) => setPropertyValue(e.target.value)} />
          </FormControl>
          {/* Existing fields continue */}
          <FormControl id="street">
            <FormLabel>Street</FormLabel>
            <Input defaultValue="" onChange={(e) => setStreet(e.target.value)} />
          </FormControl>
          <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
            <FormControl id="city">
              <FormLabel>City</FormLabel>
              <Input defaultValue="" onChange={(e) => setCity(e.target.value)} />
            </FormControl>
            <FormControl id="state">
              <FormLabel>State</FormLabel>
              <Input onChange={(e) => setState(e.target.value)} />
            </FormControl>
            <FormControl id="zip">
              <FormLabel>Postal Code</FormLabel>
              <Input defaultValue="" onChange={(e) => setPostcode(e.target.value)} />
            </FormControl>


          </Stack>
          {/* Field for describing the case */}
          <FormControl id="caseDescription">
            <FormLabel>Case Description</FormLabel>
            <Textarea
              placeholder="Describe your case in about 100 words..."
              maxLength={700} // Approximately 100 words, assuming average word length of 7 characters
            />
          </FormControl>
        </Stack>
        <Divider />
        <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
          <Button onClick={handleSubmit}>Apply</Button>
        </Flex>
      </Box>


    </>
  )


}