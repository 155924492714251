// src/pages/Login.tsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Text, Container, Heading, Stack, FormControl, FormLabel, Input, Button, Select, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";
import { useAuth } from '../contexts/AuthContext';
import { useMagic } from '../contexts/MagicProvider';
import showToast from '../utils/showToast';
import { fetchUserByEthAddress, createUser, updateUser } from "../utils/userService";
import { getApiUrl } from '../utils/helpers';

import { User } from '../types';

export const SMSLogin = () => {
    const apiUrl = getApiUrl();
    const { setAuthInfo } = useAuth();
    const navigate = useNavigate();
    const { magic } = useMagic();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [countryCode, setCountryCode] = useState('+61'); // Default to Australia
    const [isError, setIsError] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [ethAddress, setEthAddress] = useState<string>('');
    const inviteCode = new URLSearchParams(window.location.search).get('inviteCode');


    // Correct implementation of useEffect to log the inviteCode once
    useEffect(() => {
        console.log("Invite Code:", inviteCode);
    }, []); // Empty dependency array ensures this runs only once on mount



    const handleLogin = async () => {
        if (!magic) return;
        try {


            // Modify the phone number by removing the first character if it is '0'
            const modifiedNumber = phoneNumber.startsWith('0') ? countryCode + phoneNumber.substring(1) : countryCode + phoneNumber;
            console.log(modifiedNumber);


            const didToken = await magic.auth.loginWithSMS({ phoneNumber: modifiedNumber });


            const metadata = await magic?.user.getMetadata();



            if (didToken && metadata.publicAddress) {
                setAuthInfo({
                    token: didToken,
                    phoneNumber: modifiedNumber,
                    loginMethod: 'SMS',
                    userAddress: metadata.publicAddress // Set userAddress to an empty string or provide a meaningful default if necessary
                });
                showToast({ message: "Login successful", type: 'success' });

                setEthAddress(metadata.publicAddress);

                // Fetch the user by ETH address
                const user = await fetchUserByEthAddress(metadata.publicAddress, apiUrl);

                if (!user) {
                    // Create a new user if not exists
                    const newUser: any = {
                        phoneNumber: modifiedNumber,
                        addressETH: metadata.publicAddress,
                        inviteCode: inviteCode  // Including invite code when creating a new user
                    };
                    await createUser(newUser, apiUrl);
                    onOpen(); // Show modal to collect first and last name
                } else if (!user.firstName || !user.lastName) {
                    onOpen(); // Show modal to collect first and last name
                } else {
                    navigate('/dashboard');
                }
            } else {
                showToast({ message: "Failed to retrieve token", type: 'error' });
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
            showToast({ message: errorMessage, type: 'error' });
        }
    };

    const handleCountryCodeChange = (e: any) => {
        setCountryCode(e.target.value);
    };

    const handleSaveName = async () => {
        try {
            const updatedUser = await updateUser({ addressETH: ethAddress, firstName, lastName }, apiUrl);
            if (updatedUser) {
                showToast({ message: "User information updated successfully", type: 'success' });
                onClose();
                navigate('/dashboard');
            } else {
                showToast({ message: "Failed to update user information", type: 'error' });
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
            showToast({ message: errorMessage, type: 'error' });
        }
    };

    return (
        <>
            <NavBarLogic />
            <Box as="section">
                <Container py={{ base: "16", md: "24" }}>
                    <Stack spacing={{ base: "8", md: "10" }}>
                        <Stack spacing={{ base: "4", md: "5" }} align="center">
                            <Heading size={{ base: "sm", md: "md" }}>Login</Heading>
                            <FormControl>
                                <Select id="country-code" value={countryCode} onChange={handleCountryCodeChange}>
                                    <option value="+61">+61 Australia</option>
                                    <option value="+1">+1 USA</option>
                                    <option value="+44">+44 UK</option>
                                    <option value="+57">+57 CO</option>
                                </Select>
                                <FormLabel>Phone Number</FormLabel>
                                <Input
                                    type="tel"
                                    placeholder="Enter your phone number"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </FormControl>

                            <Text><b>Invited by: </b>{inviteCode}</Text>
                            <Button onClick={handleLogin} mt={4} colorScheme="blue">
                                Connect
                            </Button>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Footer />

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Complete Your Profile</ModalHeader>
                    <ModalBody>
                        <FormControl>
                            <FormLabel>First Name</FormLabel>
                            <Input
                                placeholder="Enter your first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Last Name</FormLabel>
                            <Input
                                placeholder="Enter your last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSaveName}>
                            Continue
                        </Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
