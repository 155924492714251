// utils/invitationService.ts
// Type definition for an Inviter object
interface Inviter {
    _id: string; // Assuming _id is a string, adjust according to your actual data model
    totalInvites: number;
}

// Updated fetchTopInviters function with proper TypeScript annotations
export const fetchTopInviters = async (apiUrl: string): Promise<Inviter[]> => {
    try {
        const response = await fetch(`${apiUrl}/api/top-inviters`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error, status = ${response.status}`);
        }
        return await response.json(); // This will return the parsed JSON data directly
    } catch (error) {
        console.error('Error fetching top inviters:', error);
        throw error; // Rethrowing the error to handle it in the calling context
    }
};

