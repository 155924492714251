// src/pages/Home.tsx
import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Image,
  AspectRatio,
  SimpleGrid,
  Stack,
  StackDivider
} from "@chakra-ui/react";



import { StatsWithNumbers } from "../components/StatsWithNumbers";
import { StoriesWithAccordion } from "../components/StoriesWithAccordion";
import { Opportunities } from "../components/Opportunities";
import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";


import { ApplicationFormCard } from '../components/ApplicationFormCard';


export const Apply = () => {

  return (
    <>
      <NavBarLogic ></NavBarLogic>

      <Box as="section" >
        <Container py={{ base: '16', md: '24' }}>
          <Stack spacing={{ base: '8', md: '10' }}>
            <Stack spacing={{ base: '4', md: '5' }} align="center">
              <Heading size={{ base: 'sm', md: 'md' }}>Application for Finance</Heading>
              {/* <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
                Fill in the following questions below to see if you qualify.
              </Text> */}
            </Stack>

          </Stack>
        </Container>
      </Box>





      <Container py={{ base: '4', md: '8' }}>
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '5', lg: '8' }}
            justify="space-between"
          >
            <Box flexShrink={0}>
              <Text textStyle="lg" fontWeight="medium">
                Application form
              </Text>
              <Text color="fg.muted" textStyle="sm">
                Please answer the following questions to get started.
              </Text>
            </Box>
            <ApplicationFormCard maxW={{ lg: '3xl' }} />
          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '5', lg: '8' }}
            justify="space-between"
          >
            {/* <Box flexShrink={0}>
              <Text textStyle="lg" fontWeight="medium">
                Your Profile
              </Text>
              <Text color="fg.muted" textStyle="sm">
                Tell others who you are
              </Text>
            </Box> */}
            {/* <ProfileCard maxW={{ lg: '3xl' }} /> */}
          </Stack>
        </Stack>
      </Container>






      <Box id="footer">
        <Footer />


      </Box>
    </>
  );
};
