// src/pages/calendar/index.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Box,
    Container,
    Heading,
    Spacer,
    useToast,
    Image,
    Flex,
    Button,
    Center
} from '@chakra-ui/react';

import { NavBarLogic } from '../../components/NavBarLogic';
import { Footer } from '../../components/Footer';
import { getBookingsByLoggerID } from '../../utils/bookingService';
import { getLogByLoggerID } from '../../utils/loggerSerice';
import { getApiUrl } from '../../utils/helpers';
import { Logger } from '../../types';


const localizer = momentLocalizer(moment);

export const CalendarView = () => {
    const [logDetails, setLogDetails] = useState<Logger | null>(null); // Correctly type the state
    const navigate = useNavigate();
    const apiUrl = getApiUrl();
    const { loggerID } = useParams();
    const [events, setEvents] = useState([]);
    const toast = useToast();
    const [inviteCode, setInviteCode] = useState<String>("");


    useEffect(() => {
        const loadEvents = async () => {
            try {

                if (loggerID) {
                    const bookings = await getBookingsByLoggerID(loggerID);
                    const calendarEvents = bookings.map(booking => ({
                        title: `Booked: ${booking.userID}`,
                        start: new Date(booking.startDate),
                        end: new Date(booking.endDate),
                        allDay: true
                    }));
                    // setEvents(calendarEvents);
                }
            } catch (error) {
                toast({
                    title: "Error loading events",
                    description: "There was an error loading the booking data.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };

        if (loggerID) {
            loadEvents();
        }
    }, [loggerID, toast]);



    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('inviteCode');
        const loadLoggerDetails = async () => {

            if (loggerID) {
                const loggerDetails = await getLogByLoggerID(apiUrl, loggerID);
                setLogDetails(loggerDetails);
                console.log(loggerDetails);

            }
        }
        loadLoggerDetails();
    }, [loggerID])


    const navigateToLogin = () => {
        // Construct the URL with the inviteCode
        const loginUrl = `/smslogin?inviteCode=${inviteCode}`;
        navigate(loginUrl);
    };

    const navigateToLog = () => {
        const logURL = `/log/${loggerID}`
        navigate(logURL)
    }

    return (
        <>
            <NavBarLogic />
            <Container py={{ base: "2", md: "4" }} maxW={800}>

                <Flex>
                    <Box p='4' w={'90%'}>
                        <Heading size='md' my={5}>
                            Booking Calendar for {logDetails?.name}
                        </Heading>
                    </Box>
                    <Spacer />
                    <Box p='4' >
                        <Image
                            mt={6}
                            src={`https://salmon-xenogeneic-dog-987.mypinata.cloud/ipfs/${logDetails?.photoLink}?pinataGatewayToken=HAMsT2G6tnoStVHWOMakvZ9dqs5_5zrpeRFgJqZsjlCr5R_zOpTf5OWNmqQ2Hbm7`}
                            alt={logDetails?.description}
                            borderRadius='lg'
                        boxSize='300px'
                        // h={'300px'}
                        />
                        {/* <Box> */}
                            {/* <Center> */}
                                <Button w='100%' mt={5} onClick={navigateToLog}>View Log</Button>

                            {/* </Center> */}

                        {/* </Box> */}





                    </Box>
                </Flex>


                <Box w='100%' h="500px">
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: "100%" }}
                    />
                </Box>
            </Container>
            <Footer />
        </>
    );
};

export default CalendarView;
